/* eslint-disable react/prop-types */
import React from 'react';
import {
  createTheme,
  MuiThemeProvider,
  StylesProvider,
  jssPreset
} from '@material-ui/core/styles';
import { grey, yellow, blue } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { create } from 'jss';
import rtl from 'jss-rtl';
import './index.scss';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';
import { PortfolioStatus } from '~/types';
import { props as defaultProperties } from './theme';
import useDirection from './useDirection';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const polarisGreen = {
  50: '#d2f9ea',
  100: '#b0f2d7',
  200: '#7de5be',
  300: '#26d293',
  400: '#00c47c',
  500: '#00b875',
  600: '#00a86b',
  700: '#00945e',
  800: '#008454',
  900: '#00643f',
  A100: '#',
  A200: '#',
  A400: '#',
  A700: '#'
};

// A theme with custom primary and secondary color.
// It's optional.
const theme = dir =>
  createTheme({
    direction: dir,
    props: defaultProperties,
    palette: {
      contrastThreshold: 4.5, // WCAG 2 level AA requires a contrast ratio of at least 4.5:1 for normal text and 3:1 for large text.
      primary: {
        light: '#d7deff',
        main: '#3758fb',
        dark: '#1736a9'
      },
      secondary: {
        light: '#fdc5d7',
        main: '#e9055b',
        dark: '#91054d'
      },
      success: {
        light: '#d2f9ea',
        main: '#26d293',
        dark: '#008454',
        contrastText: '#333',
        lightBackground: '#E9FAF4'
      },
      error: {
        light: '#fcd4d0',
        main: '#d9240f',
        dark: '#a71809',
        lightBackground: '#FBE9E7'
      },
      warning: {
        light: '#fffad7',
        main: '#fbe637',
        dark: '#917205',
        contrastText: '#333'
      },
      information: {
        light: '#c6ecfc',
        main: '#37bbfb',
        dark: '#005ba4',
        contrastText: '#333'
      },
      dtm: {
        main: '#0069aa',
        text: '#fff'
      },
      Dela: {
        darkIcon: '#4a01e0',
        mainText: '#8980fe',
        lightText: '#919cf8',
        darkText: '#3758fb',
        mainBackground: '#ecdff6',
        lightBackground: '#f7f1fb',
        darkBackground: '#eee8f2',
        button: {
          gradient: {
            purple: '#8b34c3',
            blue: '#423fe2'
          },
          border: '#b882d9'
        }
      },
      table: {
        header: 'rgba(0, 0, 0, 0.54)',
        body: 'rgba(0, 0, 0, 0.87)',
        border: 'rgba(0, 0, 0, 0.12)'
      },
      common: {
        highlight: yellow[50]
      },
      cost: {
        actual: '#c258d4',
        actualLabel: '#c258d4',
        estimated: '#bdbdbd'
      },
      billing: {
        revenue: '#37bbfb',
        estimated: '#bdbdbd'
      },
      hours: {
        actual: '#2fc9cb',
        actualLabel: blue[300],
        estimated: '#bdbdbd'
      },
      projectWorkflow: {
        [PROJECT_EXECUTION_PHASE.INITIATE]: '#0bb8df',
        [PROJECT_EXECUTION_PHASE.PLANNING]: '#f1a057',
        [PROJECT_EXECUTION_PHASE.EXECUTION]: '#26d293',
        [PROJECT_EXECUTION_PHASE.CLOSEOUT]: '#6c91a8',
        [PROJECT_EXECUTION_PHASE.ARCHIVED]: '#bdbdbd'
      },
      projectWorkflowBGColor: {
        [PROJECT_EXECUTION_PHASE.INITIATE]: '#cff4fd',
        [PROJECT_EXECUTION_PHASE.PLANNING]: '#fbe7d5',
        [PROJECT_EXECUTION_PHASE.EXECUTION]: '#d2f9ea',
        [PROJECT_EXECUTION_PHASE.CLOSEOUT]: '#e1e8ed',
        [PROJECT_EXECUTION_PHASE.ARCHIVED]: '#f5f5f5'
      },
      projectRequestStatus: {
        NEW: '#8dabbb',
        IN_REVIEW: '#f0c142',
        APPROVED: '#26d293',
        REJECTED: '#d9240f'
      },
      projectRequestStatusButtons: {
        pending: {
          light: '#fbf0d0',
          main: '#f0c142',
          dark: '#8e6b0b'
        },
        approve: {
          light: '#d2f9ea',
          main: '#26d293',
          dark: '#00754a'
        },
        primary: {
          light: '#d7deff',
          main: '#3758fb',
          dark: '#1736a9'
        }
      },
      portfolioStatus: {
        enabledPortfolioRow: {
          [PortfolioStatus.Active]: polarisGreen[300],
          [PortfolioStatus.Inactive]: grey[400]
        },
        disabledPortfolioRow: {
          [PortfolioStatus.Active]: polarisGreen[100],
          [PortfolioStatus.Inactive]: grey[300]
        }
      },
      taskStatus: {
        NOTSTARTED: {
          color: '#0bb8df',
          lightColor: '#cff4fd'
        },
        INPROGRESS: {
          color: '#26d293',
          lightColor: '#d2f9ea'
        },
        COMPLETED: {
          color: '#6c91a8',
          lightColor: '#e1e8ed'
        }
      },
      allocationChartStatus: {
        available: {
          main: '#fff',
          border: grey[700]
        },
        allocated: {
          main: '#26d293'
        },
        overAllocated: {
          main: '#856ddb'
        },
        timeOff: {
          main: '#6c91a8'
        },
        holidays: {
          main: '#8dabbb'
        },
        pending: {
          main: '#f0c142'
        },
        toBeHired: {
          main: '#d27732'
        },
        actual: {
          main: '#000',
          hours: {
            nonZero: '#FFF',
            zero: '#000'
          }
        }
      },
      text: {
        main: 'rgba(0, 0, 0, 0.87)',
        white: '#ffffff',
        secondary: 'rgba(0, 0, 0, 0.6)'
      },
      action: {
        active: 'rgba(0, 0, 0, 0.6)'
      },
      client: {
        active: {
          light: '#d2f9ea',
          main: '#26d293'
        },
        inActive: {
          light: '#f5f5f5',
          main: '#bdbdbd'
        }
      },
      program: {
        active: {
          active: {
            light: '#d2f9ea',
            main: '#26d293'
          },
          inActive: {
            light: '#f5f5f5',
            main: '#bdbdbd'
          }
        }
      },
      accountReceivableStatus: {
        thirtyDays: {
          main: '#fbe637',
          light: '#fefdd1'
        },
        sixtyDays: {
          main: '#F1AB2B',
          light: '#FCEED4'
        },
        ninetyDays: {
          main: '#EA8523',
          light: '#fbe8d6'
        },
        oneTwentyDays: {
          main: '#E35F1B',
          light: '#F9DFD1'
        },
        greaterThanoneTwentyDays: {
          main: '#d9240f',
          light: '#fcdbd7'
        }
      },

      project: {
        initiate: {
          light: '#cff4fd',
          main: '#0bb8df'
        },
        planning: {
          light: '#fbe7d5',
          main: '#f1a057'
        },
        execution: {
          light: '#d2f9ea',
          main: '#26d293'
        },
        closeout: {
          light: '#e1e8ed',
          main: '#6c91a8'
        },
        archived: {
          light: '#f5f5f5',
          main: '#bdbdbd'
        }
      },
      task: {
        notStarted: {
          light: '#cff4fd',
          main: '#0bb8df'
        },
        inProgress: {
          light: '#d2f9ea',
          main: '#26d293'
        },
        complete: {
          light: '#e1e8ed',
          main: '#6c91a8'
        },
        milestone: '#8a06ee'
      },
      taskRollUp: {
        text: {
          subHeading: '#868686',
          light: '#a5a5a5'
        },
        exceeded: {
          light: '#ffc87e',
          main: '#854b00'
        },
        under: {
          light: '#ffeeb2',
          main: '#896c08'
        },
        missing: {
          light: '#d0d0d0',
          main: '#676767'
        }
      },
      resourceRequest: {
        draft: {
          light: '#f5f5f5',
          main: '#bdbdbd',
          dark: '#616161'
        },
        pending: {
          light: '#fbf0d0',
          main: '#f0c142',
          dark: '#8e6b0b'
        },
        requestRejected: {
          light: '#fcd4d0',
          main: '#d9240f',
          dark: '#a71809'
        },
        toBeHired: {
          light: '#f1d3bc',
          main: '#d27732',
          dark: '#82481c'
        },
        proposed: {
          light: '#cff4fd',
          main: '#0bb8df',
          dark: '#087690'
        },
        complete: {
          light: '#d2f9ea',
          main: '#26d293',
          dark: '#00754a'
        },
        resourceRejected: {
          light: '#fcd4d0',
          main: '#d9240f',
          dark: '#a71809'
        }
      },
      approvalStatus: {
        submitting: {
          light: '#f5f5f5',
          main: '#bdbdbd'
        },
        notSubmitted: {
          light: '#f5f5f5',
          main: '#bdbdbd'
        },
        waitingForApproval: {
          light: '#fbe7d5',
          main: '#f1a057'
        },
        rejected: {
          light: '#fcd0d1',
          main: '#d9240f'
        },
        approved: {
          light: '#d2f9ea',
          main: '#26d293'
        }
      },
      bill: {
        unpaid: {
          light: '#cff4fd',
          main: '#0bb8df'
        },
        partiallyPaid: {
          light: '#cafbfe',
          main: '#2fc9cb'
        },
        paid: {
          light: '#d2f9ea',
          main: '#26d293'
        },
        draft: {
          light: '#f5f5f5',
          main: '#bdbdbd',
          dark: '#616161'
        },
        void: {
          light: '#fcd4d0',
          main: '#d9240f',
          dark: '#a71809'
        }
      },
      payment: {
        unallocated: {
          light: '#fbe7d5',
          main: '#f1a057'
        },
        partiallyAllocated: {
          light: '#fbe0d5',
          main: '#f18257'
        },
        allocated: {
          light: '#e1e8ed',
          main: '#6c91a8'
        }
      },
      creditMemo: {
        open: {
          light: '#fbe7d5',
          main: '#f1a057'
        },
        partiallyClosed: {
          light: '#fbe0d5',
          main: '#f18257'
        },
        closed: {
          light: '#e1e8ed',
          main: '#6c91a8'
        }
      },
      quality: {
        verySatisfied: '#26d293',
        satisfied: '#2fc9cb',
        neutral: grey[500],
        dissatisfied: '#f1a057',
        veryDissatisfied: '#d9240f'
      },
      score: {
        red: {
          light: '#fcd4d0',
          main: '#d9240f',
          dark: '#a71809'
        },
        yellow: {
          light: '#fffad7',
          main: '#fbe637',
          dark: '#917205'
        },
        green: {
          light: '#d2f9ea',
          main: '#26d293',
          dark: '#008454'
        }
      },
      resourceAllocation: {
        allocated: 'rgba(38, 210, 147, 1)', // rgba for #26d293
        overAllocated: 'rgba(133, 109, 219, 1)', // rgba for #856ddb
        underAllocated: 'rgba(210, 249, 234, 1)', // rgba #d2f9ea. Used only for blending colors and not used as a primary color
        timeOff: '#6c91a8',
        holiday: '#8dabbb',
        notWorking: '#e0e0e0',
        actual: '#000'
      },
      projectRisk: {
        score: {
          noRisk: '#26d293',
          littleRisk: '#fbe637',
          someRisk: '#ffb400',
          highRisk: '#d9240f'
        }
      },
      repliconAi: {
        iconBackground: '#110434'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: 'Roboto, Helvetica, Segoe UI, Arial, sans-serif',
      fontSize: 13,
      h1: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h2: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h3: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h4: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h5: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      h6: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      subtitle1: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      subtitle2: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      },
      button: {
        fontFamily: 'Poppins, Roboto, Helvetica, Segoe UI, Arial, sans-serif',
        fontWeight: 500
      }
    },
    transition: {
      timing: '0.6s'
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: '1.5rem'
        },
        fontSizeSmall: {
          fontSize: '1.25rem'
        },
        fontSizeLarge: {
          fontSize: '2.1875rem'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          '& h6': {
            color: '#fff'
          }
        }
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          '& span': {
            color: 'rgba(0,0,0,0.6)',
            backgroundColor: '#fff'
          }
        }
      },
      MuiTableCell: {
        root: {
          '&:last-child': {
            paddingRight: '8px'
          }
        }
      },
      MuiTableRow: {
        root: {
          height: '0px'
        }
      },
      MuiSelect: {
        selectMenu: {
          height: undefined
        }
      },
      MuiFormControlLabel: {
        label: {
          fontSize: '0.8125rem'
        }
      }
    },
    padding: {
      input: '20px 12px 6px 12px'
    }
  });

/**
 *
 * The variable `themeWithoutDir` gives a theme without direction.
 *
 * @result Will provide a the theme object with theme.direction = undefined
 *
 * To only be used for variables and files that are not easily accessible
 * by the theme variable. Usually, the theme variable can be accessed
 * by withStyles or makeStyles hook if applied directly on the component.
 *
 */

export const themeWithoutDir = theme();

const withRoot = Component => props => {
  const { me } = props;

  const direction = useDirection(me);

  if (direction === 'rtl') {
    document.dir = 'rtl';
  }

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme(direction)}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} direction={direction} />
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default withRoot;
