import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { CardExpansionPanel } from '~/modules/common/components';
import {
  HiringRequisitionInfoTitle,
  HiringRequisitionInfoContent
} from './components';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    width: '100%',
    padding: theme.spacing(0, 0, 6)
  }
}));

export const useExpansionStyles = makeStyles(theme => ({
  expansionPanel: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 !important'
  },
  expansionPanelSummary: {
    alignItems: 'center',
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
  },
  expansionPanelSummaryContent: {
    padding: 0
  },
  expandIcon: {
    padding: theme.spacing(1.5, 2, 1.5, 0.5)
  },
  expansionPanelDetails: {
    overflow: 'hidden'
  }
}));

export const HiringRequisitionInfoExpansionPanel = ({ requisitionInfo }) => {
  const classes = useStyles();
  const expansionClasses = useExpansionStyles();

  return (
    <CardExpansionPanel
      elevation={0}
      TitleComponent={HiringRequisitionInfoTitle}
      classes={expansionClasses}
    >
      <Grid container className={classes.container}>
        <HiringRequisitionInfoContent requisitionInfo={requisitionInfo} />
      </Grid>
    </CardExpansionPanel>
  );
};

HiringRequisitionInfoExpansionPanel.propTypes = {
  requisitionInfo: PropTypes.object.isRequired
};
export default HiringRequisitionInfoExpansionPanel;
