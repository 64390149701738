import { useState, useCallback, useMemo } from 'react';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { NETWORK_STATUS } from '~/modules/common/enums';
import { mapToEffectiveTaskStatus } from '~/modules/tasks/modecs/taskTransforms';

export const DEFAULT_PAGE_SIZE = 500;

export const taskStartEndDateFragment = gql`
  fragment TaskStartEndDate on Task {
    startDate
    endDate
  }
`;

export const taskFragment = gql`
  fragment Task on Task {
    id
    uri
    name
    code @include(if: $isPsaPrpTaskCodeOnTaskCreationEnabled)
    displayText
    parent {
      id
      uri
      displayText
    }
    ...TaskStartEndDate
    assignedUser {
      id
      displayText
    }
    assignedRole {
      id
      displayText
    }
    isClosed
    isClosedByTaskInheritance
    taskStatus
    overriddenTaskStatus @include(if: $isPsaPrpManualTaskStatusEnabled)
    initialEstimatedHours
    totalActualHours
    isMilestone
    fullPath {
      id
      uri
      displayText
    }
    estimatedCompletionHours
    estimatedCompletionDate
    assignedUserRoleId
    rolledUpSummary
      @include(if: $isRolledUpTaskEstimateCalculationMethodEnabled) {
      id
      actualHours
      totalEstimatedAtCompletionHours
      totalEstimatedRemainingHours
      earliestTimeEntryDate
      estimatedCompletionDate
    }
    rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
      totalEstimatedAtCompletionCostInProjectCurrency {
        amount
        currency {
          id
          symbol
        }
      }
      initialEstimatedCostInProjectCurrency {
        amount
        currency {
          id
          symbol
        }
      }
    }
    hasChildren @include(if: $includeHasChildrenField)
    resourceEstimatesSummary @include(if: $isPsaRmpTaskAllocation1Enabled) {
      resourceEstimatesCount
      resourceEstimatesWithUsersCount
    }
  }
  ${taskStartEndDateFragment}
`;

export const projectTasksFragment = gql`
  fragment ProjectTasks on Project {
    id
    name
    code @include(if: $isPsaPrpTaskCodeOnTaskCreationEnabled)
    startDate: startDate2
    endDate: endDate2
    timeAndExpenseEntryType {
      id
    }
    tasks(
      isOpen: $isOpen
      textSearch: $textSearch
      page: $page
      pageSize: $pageSize
    ) {
      ...Task
    }
    rolledUpSummary
      @include(if: $isRolledUpTaskEstimateCalculationMethodEnabled) {
      id
      actualHours
      totalEstimatedAtCompletionHours
      totalEstimatedRemainingHours
      earliestTimeEntryDate
      estimatedCompletionDate
    }
    rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
      totalEstimatedAtCompletionCostInProjectCurrency {
        amount
        currency {
          id
          symbol
        }
      }
      budgetedCostInProjectCurrency {
        amount
        currency {
          id
          symbol
        }
      }
    }
  }
  ${taskFragment}
`;

export const projectTasksQuery = gql`
  query projectTasksQuery(
    $projectSlug: String!
    $isOpen: Boolean
    $textSearch: String
    $isMilestone: Boolean
    $page: Int!
    $pageSize: Int!
    $includeHasChildrenField: Boolean = false
    $includeRolledUpCostSummary: Boolean = false
    $isRolledUpTaskEstimateCalculationMethodEnabled: Boolean = false
    $isPsaPrpTaskCodeOnTaskCreationEnabled: Boolean = false
    $isPsaRmpTaskAllocation1Enabled: Boolean = false
    $isPsaPswatTaskDateRollupEnabled: Boolean = false
    $isPsaPrpManualTaskStatusEnabled: Boolean = false
  ) {
    project(projectSlug: $projectSlug) {
      ...ProjectTasks
      tasksCount(
        isOpen: $isOpen
        textSearch: $textSearch
        isMilestone: $isMilestone
      )
      openAndClosedTaskCount: tasksCount
        @include(if: $isPsaPswatTaskDateRollupEnabled)
    }
  }
  ${projectTasksFragment}
`;

const updateQueryOnFetchMoreResult = (
  prev = { project: { tasks: [] } },
  {
    fetchMoreResult = {
      project: { tasks: [] }
    }
  }
) => {
  if (!fetchMoreResult) return prev;

  const prevTasks = prev.project.tasks || [];
  const moreTasks = fetchMoreResult.project.tasks || [];

  return {
    project: {
      ...fetchMoreResult.project,
      tasks: [...prevTasks, ...moreTasks],
      __typename: 'Project'
    }
  };
};

const transformTask = ({
  task,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  isPsaPrpManualTaskStatusEnabled
}) => {
  return {
    ...task,
    ...(isPsaPrpManualTaskStatusEnabled && {
      taskStatus: mapToEffectiveTaskStatus(
        task,
        isRolledUpTaskEstimateCalculationMethodEnabled
      )
    })
  };
};

export const useProjectTasks = ({
  me,
  projectSlug,
  includeClosedTasks,
  textSearch
}) => {
  const {
    featureFlags: {
      isPSATaskEstimateRollUpMobileViewEnabled,
      isPsaPpmCostEacEnhancements2Enabled,
      isPsaPrpTaskCodeOnTaskCreationEnabled,
      isPsaRmpTaskAllocation1Enabled,
      isPsaPswatTaskDateRollupEnabled,
      isPsaPrpManualTaskStatusEnabled
    },
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = me;
  const [loadingMore, setLoadingMore] = useState(false);
  const emptyObject = useMemo(() => ({}), []);
  const includeRolledUpCostSummary =
    isPsaPpmCostEacEnhancements2Enabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled;
  const includeHasChildrenField =
    isPSATaskEstimateRollUpMobileViewEnabled || isPsaRmpTaskAllocation1Enabled;

  const { data, error, loading, fetchMore, networkStatus } = useQuery(
    projectTasksQuery,
    {
      variables: {
        projectSlug,
        isOpen: includeClosedTasks ? undefined : true,
        textSearch,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        isRolledUpTaskEstimateCalculationMethodEnabled,
        isPsaPrpTaskCodeOnTaskCreationEnabled,
        isPsaRmpTaskAllocation1Enabled,
        includeHasChildrenField,
        includeRolledUpCostSummary,
        isPsaPswatTaskDateRollupEnabled,
        isPsaPrpManualTaskStatusEnabled
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const project = get(data, 'project');
  const tasks = get(data, 'project.tasks', []);
  const tasksCount = get(data, 'project.tasksCount');
  const variables = get(data, 'variables');
  const hasMoreTasks = tasks && tasks.length !== 0 && tasksCount > tasks.length;
  const openAndClosedTaskCount = get(data, 'project.openAndClosedTaskCount', 0);

  const loadMoreTasks = useCallback(async () => {
    if (
      !hasMoreTasks ||
      loadingMore ||
      loading ||
      networkStatus !== NETWORK_STATUS.READY
    ) {
      return;
    }

    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          ...variables,
          page: Math.floor(tasks.length / variables.pageSize) + 1
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [
    hasMoreTasks,
    loading,
    networkStatus,
    fetchMore,
    variables,
    tasks.length,
    setLoadingMore,
    loadingMore
  ]);

  return {
    project: {
      ...project,
      rolledUpSummary: project?.rolledUpSummary || emptyObject,
      rolledUpCostSummary: project?.rolledUpCostSummary || emptyObject,
      tasks:
        tasks?.map(task =>
          transformTask({
            task,
            isRolledUpTaskEstimateCalculationMethodEnabled,
            isPsaPrpManualTaskStatusEnabled
          })
        ) || [],
      openAndClosedTaskCount
    },
    hasMoreRows: hasMoreTasks,
    loadingRows: loading,
    loadMoreRows: loadMoreTasks,
    error
  };
};

export default useProjectTasks;
