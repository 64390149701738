import { useCallback } from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { startIsAfterEnd } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor/common/taskAllocationEditorUtil';
import { calculateProjectAllocationHours } from '~/modules/resourcing/common/util';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';

export const onDateChange = async ({
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  setValues,
  values,
  taskResourceUserAllocation,
  refetchTotalAvailableDurationForDateRange,
  userUri
}) => {
  if (!startDate || !endDate) {
    setValues({
      ...values,
      startDate,
      endDate,
      newTaskAllocationHours: null
    });
  } else {
    const {
      workDuration,
      allocatedDuration
    } = await refetchTotalAvailableDurationForDateRange({
      resourceUserId: userUri,
      startDate,
      endDate
    });

    const start = mapRepliconDateToUtcObject(startDate);
    const end = mapRepliconDateToUtcObject(endDate);
    const initialProjectAllocationHours =
      getTotalHoursForDateRangeFromScheduleRules({
        start,
        end,
        scheduleRules: resourceAllocationScheduleRules || []
      }) || 0;

    const otherTaskAllocationHours =
      getTotalHoursForDateRangeFromScheduleRules({
        start,
        end,
        scheduleRules: otherTaskAllocationsSummaryScheduleRules || []
      }) || 0;

    setValues({
      ...values,
      startDate,
      endDate,
      otherTaskAllocationHours,

      initialProjectAllocationHours,
      newProjectAllocationHours: initialProjectAllocationHours,

      newWorkHours: workDuration,
      newTotalResourceAllocatedHours: allocatedDuration
    });
  }
};

const useFormChangeHandlers = ({
  values,
  setValues,
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  userUri,
  taskResourceUserAllocation,
  refetchTotalAvailableDurationForDateRange
}) => ({
  onStartDateChange: useCallback(
    async val => {
      await onDateChange({
        startDate: val,
        endDate:
          endDate && startIsAfterEnd({ start: val, end: endDate })
            ? val
            : endDate,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        setValues,
        userUri,
        taskResourceUserAllocation,
        refetchTotalAvailableDurationForDateRange,
        values
      });
    },
    [
      endDate,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      setValues,
      userUri,
      taskResourceUserAllocation,
      refetchTotalAvailableDurationForDateRange,
      values
    ]
  ),
  onEndDateChange: useCallback(
    async val => {
      await onDateChange({
        startDate:
          startDate && startIsAfterEnd({ start: startDate, end: val })
            ? val
            : startDate,
        endDate: val,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        setValues,
        userUri,
        taskResourceUserAllocation,
        refetchTotalAvailableDurationForDateRange,
        values
      });
    },
    [
      refetchTotalAvailableDurationForDateRange,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      setValues,
      startDate,
      taskResourceUserAllocation,
      userUri,
      values
    ]
  ),
  onTaskAllocationHoursChange: useCallback(
    event => {
      const {
        initialTaskAllocationHours,
        initialProjectAllocationHours,
        otherTaskAllocationHours
      } = values;
      const newTaskAllocationHours = event.target.value || 0;
      const newProjectAllocationHours = calculateProjectAllocationHours({
        initialTaskAllocationHours,
        initialProjectAllocationHours,
        initialTotalTaskAllocationHours:
          initialTaskAllocationHours + otherTaskAllocationHours,
        newTaskAllocationHours
      });

      setValues({
        ...values,
        newTaskAllocationHours,
        newProjectAllocationHours
      });
    },
    [setValues, values]
  )
});

export default useFormChangeHandlers;
