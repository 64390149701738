import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Formik } from 'formik';
import { useFormState, useOnSubmit } from './hooks';
import { ChangeResourceDialogBody } from './ChangeResourceDialogBody';

export const ChangeResourceDialog = ({
  existingResourceUser,
  onChangeResourceSuccess,
  onClose,
  open,
  projectId
}) => {
  const { initialValues } = useFormState();

  const { onChangeResourceUserSubmit } = useOnSubmit({
    existingResourceUserId: existingResourceUser.user.id,
    onClose,
    onChangeResourceSuccess,
    projectId
  });

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={onChangeResourceUserSubmit}
      >
        <ChangeResourceDialogBody
          existingResourceUser={existingResourceUser}
          projectId={projectId}
          onClose={onClose}
        />
      </Formik>
    </Dialog>
  );
};

ChangeResourceDialog.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  onChangeResourceSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ChangeResourceDialog;
