import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

export const ChangeResourceDialogFooter = ({
  hasTaskAssignmentConflict,
  isTaskAssignmentConflictLoading,
  onClose
}) => {
  const {
    handleSubmit,
    isSubmitting,
    values: { newResourceUser }
  } = useFormikContext();

  return (
    <DialogActions>
      <Button disabled={isSubmitting} onClick={onClose}>
        <FormattedMessage id="changeResourceDialog.cancel" />
      </Button>
      <Button
        color="primary"
        disabled={
          !newResourceUser ||
          isTaskAssignmentConflictLoading ||
          hasTaskAssignmentConflict ||
          isSubmitting
        }
        onClick={handleSubmit}
      >
        <FormattedMessage id="changeResourceDialog.changeResource" />
      </Button>
    </DialogActions>
  );
};

ChangeResourceDialogFooter.propTypes = {
  hasTaskAssignmentConflict: PropTypes.bool,
  isTaskAssignmentConflictLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default ChangeResourceDialogFooter;
