import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

const label = (
  <FormattedMessage id="massEditProject.projectManagerTimesheetApproval" />
);

const getOptions = intl => [
  {
    id: 'noApproval',
    displayText: intl.formatMessage({ id: 'massEditProject.noApproval' })
  },
  {
    id: 'required',
    displayText: intl.formatMessage({ id: 'massEditProject.required' })
  }
];

export const TimeSheetApprovalDropdown = ({ value, onChange }) => {
  const intl = useIntl();
  const options = getOptions(intl);

  return (
    <SimpleDropdown
      variant="filled"
      label={label}
      noneOption={false}
      options={options}
      onChange={onChange}
      value={options.find(o => o.id === value?.id)}
    />
  );
};

TimeSheetApprovalDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default TimeSheetApprovalDropdown;
