import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { TaskAllocationTimelineBlocks } from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import {
  TaskAllocationTimelineEditorOverlay,
  ResourceAllocationChangeSnackBar,
  UserRoleActualsTimeLineRow
} from '~/modules/resourcing/common/components';
import {
  useUpdateTaskResourceUserAllocation,
  useCreateTaskResourceUserAllocation
} from '~/modules/common/components/TaskDrawer/common/hooks';
import { useResourceTimelineEditorChangeHandlers } from '~/modules/resourcing/common/hooks';
import { useTaskAssignmentTimelineEditorChangeHandlers } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import { useDialogState } from '~/modules/common/hooks';
import { useUserAllocationsSummaryContext } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/components/ResourceAllocationChartRow/UserAllocationsSummaryContext.js';
import { RangeBoundaries } from '~/modules/common/charts/timeline/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import ReleaseTaskDialogsContainer from './ReleaseTaskDialogsContainer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  actualsRoot: ({ allocationsCount }) => ({
    minHeight: (allocationsCount + 1) * theme.spacing(4) + 4
  })
}));

const mapTaskAllocationToAllocationTimeline = ({
  user,
  userScheduleDetails,
  taskResourceUserAllocation = {}
}) => {
  const { displayText, id, uri } = user;
  const { scheduleDurationByPeriodMap } = userScheduleDetails;

  return {
    user: {
      id,
      uri,
      scheduleDurationByPeriodMap,
      displayText,
      user: {
        id,
        uri,
        displayText
      }
    },
    ...taskResourceUserAllocation
  };
};

export const getOverDistributedDisplayProperties = ({
  userOverDistributedPeriodsMap
}) => ({ periodStart }) => ({
  isOverDistributed: Boolean(userOverDistributedPeriodsMap[periodStart.toISO()])
});

export const TaskAssignmentTimeline = ({
  chartDisplayPeriods,
  handleRemoveTaskAssignment,
  user,
  taskActualSeriesData,
  taskResourceEstimate,
  taskResourceUserAllocation,
  userScheduleDetails
}) => {
  const classes = useStyles({
    allocationsCount: taskResourceUserAllocation ? 1 : 0
  });
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();
  const {
    scale,
    chartDates,
    dateRange,
    displayUnit,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();
  const {
    project,
    projectObjectPermissions: { canEditTasks }
  } = useProjectContext();
  const { id: projectId, startDate, endDate } = project;
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: ''
  });

  const {
    loadingResourceAllocation,
    resourceAllocationScheduleRules,
    userOverDistributedPeriodsMap,
    userTaskAllocationsSummaryLoading,
    userTaskAllocationsSummaryScheduleRules
  } = useUserAllocationsSummaryContext();

  const updateTaskResourceUserAllocation = useUpdateTaskResourceUserAllocation();
  const createTaskResourceUserAllocation = useCreateTaskResourceUserAllocation();

  const [allocationPeriodEditTarget, setAllocationPeriodEditTarget] = useState(
    null
  );

  const {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = useResourceTimelineEditorChangeHandlers({
    allocationPeriodEditTarget,
    chartDates,
    isPercentageMode: displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE,
    setAllocationPeriodEditTarget,
    resourceAllocation: taskResourceUserAllocation || {}
  });

  const {
    open: releaseTaskDialogsContainerOpen,
    openDialog: openReleaseTaskDialogsContainer,
    closeDialog: closeReleaseTaskDialogsContainer
  } = useDialogState(false);

  const {
    onPeriodClose,
    onAllocationChange
  } = useTaskAssignmentTimelineEditorChangeHandlers({
    updateTaskResourceUserAllocation,
    createTaskResourceUserAllocation,
    taskResourceUserAllocation,
    taskResourceEstimate,
    projectId,
    userId: user.id,
    openReleaseTaskDialogsContainer,
    setSnackBarState
  });

  const mappedTaskAllocation = mapTaskAllocationToAllocationTimeline({
    user,
    userScheduleDetails,
    taskResourceUserAllocation
  });

  return (
    <div
      className={classNames(classes.root, {
        [classes.actualsRoot]:
          isResourceActualModeEnabled && isRmpTaskAllocationPhase2Enabled
      })}
    >
      <RangeBoundaries
        chartStartDate={dateRange.startDate}
        scale={scale}
        start={startDate}
        end={endDate}
      />
      <TaskAllocationTimelineBlocks
        taskAllocation={mappedTaskAllocation}
        chartDisplayPeriods={chartDisplayPeriods}
        scale={scale}
        isEditable={canEditTasks}
        chartDisplayDateRange={dateRange}
        onAllocationChange={onAllocationChange}
        handleAllocationPeriodClick={handleAllocationPeriodClick}
        getCustomDisplayPeriodProps={
          taskResourceUserAllocation &&
          getOverDistributedDisplayProperties({
            userOverDistributedPeriodsMap
          })
        }
      />
      {isResourceActualModeEnabled && isRmpTaskAllocationPhase2Enabled && (
        <UserRoleActualsTimeLineRow
          actualSummaryBlockPopupProps={{}}
          actualsData={taskActualSeriesData}
          chartDisplayDateRange={dateRange}
          chartDisplayPeriods={chartDisplayPeriods}
          resourceAllocations={
            taskResourceUserAllocation && [taskResourceUserAllocation]
          }
          scale={scale}
          user={user}
          userScheduleDetails={userScheduleDetails}
        />
      )}
      {canEditTasks && allocationPeriodEditTarget?.anchorEl && (
        <TaskAllocationTimelineEditorOverlay
          chartDisplayDateRange={dateRange}
          scale={scale}
          loadingResourceAllocation={loadingResourceAllocation}
          resourceAllocationScheduleRules={resourceAllocationScheduleRules}
          userTaskAllocationsSummaryLoading={userTaskAllocationsSummaryLoading}
          userTaskAllocationsSummaryScheduleRules={
            userTaskAllocationsSummaryScheduleRules
          }
          allocationPeriodEditTarget={allocationPeriodEditTarget}
          onChange={onPeriodClose}
          handleAllocationPeriodClose={handleAllocationPeriodClose}
          taskResourceUserAllocation={mappedTaskAllocation}
          setNextPeriod={setNextPeriod}
          setPreviousPeriod={setPreviousPeriod}
          userId={user.id}
          resourceAvailabilitySummaryByPeriodMap={
            userScheduleDetails.resourceAvailabilitySummaryByPeriodMap
          }
        />
      )}
      {releaseTaskDialogsContainerOpen && (
        <ReleaseTaskDialogsContainer
          projectId={projectId}
          task={taskResourceEstimate.task}
          user={user}
          closeReleaseTaskDialogsContainer={closeReleaseTaskDialogsContainer}
          handleRemoveTaskAssignment={handleRemoveTaskAssignment}
        />
      )}
      {snackBarState.open && (
        <ResourceAllocationChangeSnackBar
          snackBarState={snackBarState}
          setSnackBarState={setSnackBarState}
        />
      )}
    </div>
  );
};

TaskAssignmentTimeline.propTypes = {
  taskActualSeriesData: PropTypes.object,
  taskResourceUserAllocation: PropTypes.object,
  user: PropTypes.object,
  userScheduleDetails: PropTypes.object,
  chartDisplayPeriods: PropTypes.array.isRequired,
  taskResourceEstimate: PropTypes.object,
  handleRemoveTaskAssignment: PropTypes.func
};

export default TaskAssignmentTimeline;
