import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const useTitleStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary
  }
}));

export const HiringRequisitionInfoTitle = () => {
  const classes = useTitleStyles();

  return (
    <Typography variant="subtitle1" className={classes.title}>
      <FormattedMessage id="resourceRequestDrawerReadonlyDetails.hiringRequest" />
    </Typography>
  );
};

export default HiringRequisitionInfoTitle;

HiringRequisitionInfoTitle.propTypes = {};
