import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const useTitleStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export const ChangeResourceDialogTitle = ({ onClose }) => {
  const titleClasses = useTitleStyles();
  const classes = useStyles();

  const { isSubmitting } = useFormikContext();

  return (
    <DialogTitle classes={titleClasses}>
      <FormattedMessage id="changeResourceDialog.title" />
      {!isSubmitting && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

ChangeResourceDialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired
};
