import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

const label = <FormattedMessage id="massEditProject.timeEntry" />;
const getOptions = intl => [
  {
    id: 'taskSelectionOptional',
    displayText: intl.formatMessage({
      id: 'massEditProject.taskSelectionOptional'
    })
  },
  {
    id: 'taskSelectionRequired',
    displayText: intl.formatMessage({
      id: 'massEditProject.taskSelectionRequired'
    })
  }
];

export const TimeEntryDropdown = ({ value, onChange }) => {
  const intl = useIntl();
  const options = getOptions(intl);

  return (
    <SimpleDropdown
      variant="filled"
      label={label}
      noneOption={false}
      options={options}
      onChange={onChange}
      value={options.find(o => o.id === value?.id)}
    />
  );
};

TimeEntryDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default TimeEntryDropdown;
