import { useFormik } from 'formik';
import { useMemo } from 'react';

export const useForm = ({
  workflowStage,
  currency,
  client,
  clientRepresentative,
  program,
  costType,
  projectManager,
  projectManagerTimesheetApproval,
  timeAndExpenseEntryType,
  timeEntry
}) => {
  const initialValues = useMemo(
    () => ({
      workflowStage,
      currency,
      client,
      clientRepresentative,
      program,
      costType,
      projectManager,
      projectManagerTimesheetApproval,
      timeAndExpenseEntryType,
      timeEntry
    }),
    [
      workflowStage,
      currency,
      client,
      clientRepresentative,
      program,
      costType,
      projectManager,
      projectManagerTimesheetApproval,
      timeAndExpenseEntryType,
      timeEntry
    ]
  );

  return useFormik({
    initialValues
  });
};
