import { Money, User } from '~/modules/common/components/ListTable/renderers';
import { ProjectFormatter } from '~/modules/billing-invoicing/common/columnFormatters';
import ClientRenderer from './client';
import DateFormatter from './date';
import CreateBill from './createBill';
import RowSelectorFormatter from './RowSelectorFormatter';

export default {
  rowSelector: RowSelectorFormatter,
  client: ClientRenderer,
  project: ProjectFormatter,
  projectLeader: User,
  date: DateFormatter,
  amount: Money,
  createBill: CreateBill,
  description: DateFormatter
};
