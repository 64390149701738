import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';
import { TaskResourceUserAllocationEditMode } from '~/types';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  omitForbiddenFields,
  buildCreateTaskResourceUserAllocationOptimisticResponse,
  buildUpdateTaskResourceUserAllocationOptimisticResponse
} from '~/modules/common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import { getResourceUserAvailabilityHoursSeriesForDateRangeCallback } from '~/modules/resourcing/common/hooks/useResourceUserAvailabilityHoursSeriesForDateRange';
import { TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY } from '~/modules/common/components/TaskDrawer/common/hooks/useGetTaskResourceUserAllocationsSummaryForUser';
import { BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY } from '~/modules/resourcing/common/hooks/useBulkGetTaskResourceUserAllocations';
import { getTaskAllocationModificationDetailsOnDrag } from '~/modules/resourcing/common/hooks/useTaskAllocationDragIndicators';
import {
  buildRuleConstraintsForPeriod,
  getDateRangeFromScheduleRules,
  getTotalHoursForDateRangeFromScheduleRules,
  mergePeriodRuleIntoScheduleRules
} from '~/modules/resourcing/common/util';
import {
  dateToMidnightUTCObject,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import { useUserAllocationsSummaryContext } from '../components/ResourceAllocationChartRow/UserAllocationsSummaryContext';

export const tryLoadCachedTaskResourceUserAllocationsSummaryQuery = ({
  proxy,
  variables
}) => {
  try {
    return proxy.readQuery({
      query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const updateTaskResourceUserAllocationsSummaryForUserCache = ({
  periodDetails,
  projectId,
  userId,
  proxy
}) => {
  const variables = {
    userId,
    filter: {
      projectId
    }
  };
  const results = tryLoadCachedTaskResourceUserAllocationsSummaryQuery({
    proxy,
    variables
  });

  if (!results) return;

  const { getTaskResourceUserAllocationsSummaryForUser } = results;

  const {
    periodStartDate,
    periodEndDate,
    newPeriodTaskAllocatedHours: newPeriodHours,
    periodTaskAllocatedHours: originalPeriodHours
  } = periodDetails;

  const totalUserHours = getTotalHoursForDateRangeFromScheduleRules({
    start: periodStartDate,
    end: periodEndDate,
    scheduleRules:
      getTaskResourceUserAllocationsSummaryForUser?.scheduleRules || [],
    useVersion2: true
  });

  const scheduleRule = {
    dateRange: {
      startDate: periodStartDate.toISO(),
      endDate: periodEndDate.toISO()
    },
    do: buildRuleConstraintsForPeriod({
      start: periodStartDate,
      end: periodEndDate,
      totalHours:
        (totalUserHours || 0) - (originalPeriodHours || 0) + newPeriodHours,
      quantity: 1,
      excludeWeekdays: [],
      load: 100
    })
  };

  const scheduleRules = getTaskResourceUserAllocationsSummaryForUser
    ? mergePeriodRuleIntoScheduleRules(
        getTaskResourceUserAllocationsSummaryForUser.scheduleRules,
        true
      )(scheduleRule)
    : [scheduleRule];

  const updatedTaskResourceUserAllocationSummary = {
    scheduleRules,
    startDate: scheduleRule.dateRange.startDate,
    endDate: scheduleRule.dateRange.endDate,
    totalHours: newPeriodHours,
    userId
  };

  proxy.writeQuery({
    query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
    variables,
    data: {
      getTaskResourceUserAllocationsSummaryForUser: updatedTaskResourceUserAllocationSummary
    }
  });
};

export const updateCacheOnCreateTaskAllocation = ({
  userId,
  projectId,
  taskId,
  periodDetails
}) => (proxy, { data }) => {
  const {
    createTaskResourceUserAllocation: { taskResourceUserAllocation }
  } = data;

  proxy.writeQuery({
    query: BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY,
    variables: {
      filter: {
        userUri: userId,
        projectUri: projectId,
        taskUris: [taskId]
      }
    },
    data: { taskResourceUserAllocationsForUser: [taskResourceUserAllocation] }
  });

  updateTaskResourceUserAllocationsSummaryForUserCache({
    periodDetails,
    projectId,
    userId,
    proxy
  });
};

export const updateCacheOnEditTaskAllocation = ({
  userId,
  projectId,
  periodDetails
}) => proxy => {
  updateTaskResourceUserAllocationsSummaryForUserCache({
    periodDetails,
    projectId,
    userId,
    proxy
  });
};

export const filterScheduleRules = scheduleRules =>
  scheduleRules.filter(rule => rule.do.setHours > 0);

const getCreateUpdateTaskAllocationInput = ({
  taskAllocationId,
  taskUri,
  projectUri,
  scheduleRules,
  allocationUserUri,
  isRmpTaskAllocationPhase2Enabled,
  roleUri,
  periodDetails,
  periodScheduleRules,
  allocationEditMode
}) => ({
  taskAllocationId,
  taskUri,
  projectUri,
  allocationUserUri,
  roleUri,
  ...(isRmpTaskAllocationPhase2Enabled
    ? {
        allocationEditMode,
        ...(periodDetails
          ? {
              allocationHours: periodDetails.newPeriodTaskAllocatedHours,
              dateRange: {
                startDate: periodDetails.periodStartDate,
                endDate: periodDetails.periodEndDate
              }
            }
          : omitForbiddenFields({ scheduleRules: periodScheduleRules }))
      }
    : omitForbiddenFields({ scheduleRules }))
});

export const setSnackBarMessageOnDrag = ({
  updatedTaskAllocationScheduleRules,
  startDate: dragStartDate,
  endDate: dragEndDate,
  setSnackBarState,
  formatMessage,
  taskAllocation,
  canExtendTaskAllocationBeyondProjectAllocation
}) => {
  if (canExtendTaskAllocationBeyondProjectAllocation) return;

  const { startDate, endDate } = taskAllocation;
  const isLeftExpanded = dragStartDate < mapIsoStringtoUtcObject(startDate);
  const isRightExpanded = dragEndDate > mapIsoStringtoUtcObject(endDate);

  const {
    startDate: newStartDate,
    endDate: newEndDate
  } = getDateRangeFromScheduleRules(updatedTaskAllocationScheduleRules);

  if (
    (isLeftExpanded && mapIsoStringtoUtcObject(newStartDate) > dragStartDate) ||
    (isRightExpanded && mapIsoStringtoUtcObject(newEndDate) < dragEndDate)
  ) {
    setSnackBarState({
      open: true,
      message: formatMessage({
        id: 'taskAllocationChangeSnackbar.limitedTimeToAllocationMessage'
      })
    });
  }
};
export const useTaskAssignmentTimelineEditorChangeHandlers = ({
  updateTaskResourceUserAllocation,
  createTaskResourceUserAllocation,
  taskResourceUserAllocation,
  taskResourceEstimate,
  projectId,
  userId,
  openReleaseTaskDialogsContainer,
  setSnackBarState
}) => {
  const {
    tenantSlug,
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();

  const {
    getResourceUserAvailabilityHoursSeriesForDateRange
  } = getResourceUserAvailabilityHoursSeriesForDateRangeCallback();

  const canExtendTaskAllocationBeyondProjectAllocation =
    Boolean(
      permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
    ) && isRmpTaskAllocationPhase2Enabled;

  const { formatMessage } = useIntl();

  const {
    userTaskAllocationsSummaryScheduleRules,
    resourceAllocationScheduleRules
  } = useUserAllocationsSummaryContext();

  const onPeriodClose = useCallback(
    ({
      allocation,
      periodDetails,
      periodScheduleRules,
      skipCacheUpdate = false
    }) => {
      const { scheduleRules, id, taskUri, projectUri } = allocation;
      const filteredScheduleRules = filterScheduleRules(scheduleRules);
      const newTaskAllocationId =
        !taskResourceUserAllocation &&
        `urn:replicon-tenant:${tenantSlug}:psa-task-allocation:${uuidv4()}`;

      if (taskResourceUserAllocation && filteredScheduleRules.length === 0) {
        openReleaseTaskDialogsContainer();
      } else {
        const refetchQueries = [
          {
            query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
            variables: {
              userId,
              filter: {
                projectId
              }
            }
          }
        ];

        taskResourceUserAllocation
          ? updateTaskResourceUserAllocation({
              variables: {
                input: getCreateUpdateTaskAllocationInput({
                  taskAllocationId: id,
                  taskUri,
                  projectUri,
                  periodDetails,
                  scheduleRules,
                  periodScheduleRules,
                  isRmpTaskAllocationPhase2Enabled,
                  allocationEditMode: TaskResourceUserAllocationEditMode.Partial
                })
              },
              optimisticResponse: buildUpdateTaskResourceUserAllocationOptimisticResponse(
                {
                  id,
                  taskResourceEstimate,
                  projectId,
                  userId,
                  scheduleRules
                }
              ),
              update: !skipCacheUpdate
                ? updateCacheOnEditTaskAllocation({
                    userId,
                    projectId,
                    periodDetails,
                    originalTaskResourceUserAllocation: taskResourceUserAllocation
                  })
                : undefined,
              refetchQueries
            })
          : createTaskResourceUserAllocation({
              variables: {
                input: getCreateUpdateTaskAllocationInput({
                  taskAllocationId: newTaskAllocationId,
                  taskUri: taskResourceEstimate.task.id,
                  projectUri: projectId,
                  allocationUserUri: userId,
                  periodDetails,
                  isRmpTaskAllocationPhase2Enabled,
                  scheduleRules,
                  roleUri: taskResourceEstimate.projectRole?.id
                })
              },
              optimisticResponse: buildCreateTaskResourceUserAllocationOptimisticResponse(
                {
                  id: newTaskAllocationId,
                  taskResourceEstimate,
                  projectId,
                  userId,
                  scheduleRules
                }
              ),
              update: !skipCacheUpdate
                ? updateCacheOnCreateTaskAllocation({
                    userId,
                    projectId,
                    taskId: taskResourceEstimate.task.id,
                    periodDetails
                  })
                : undefined,
              refetchQueries
            });
      }
    },
    [
      createTaskResourceUserAllocation,
      isRmpTaskAllocationPhase2Enabled,
      openReleaseTaskDialogsContainer,
      projectId,
      taskResourceEstimate,
      taskResourceUserAllocation,
      tenantSlug,
      updateTaskResourceUserAllocation,
      userId
    ]
  );

  const onAllocationChange = useCallback(
    async ({ startDate, endDate, taskAllocation }) => {
      const dragStartDate = dateToMidnightUTCObject(startDate.toISO());
      const dragEndDate = dateToMidnightUTCObject(endDate.toISO());
      const {
        taskAllocationScheduleRules,
        extendedPeriodScheduleRules,
        collapsedPeriodDetails
      } = await getTaskAllocationModificationDetailsOnDrag({
        startDate: dragStartDate,
        endDate: dragEndDate,
        taskAllocation,
        resourceAllocationScheduleRules,
        userTaskAllocationsSummaryScheduleRules,
        canExtendTaskAllocationBeyondProjectAllocation,
        getResourceUserAvailabilityHoursSeriesForDateRange
      });

      setSnackBarMessageOnDrag({
        taskAllocation,
        updatedTaskAllocationScheduleRules: taskAllocationScheduleRules,
        startDate: dragStartDate,
        endDate: dragEndDate,
        setSnackBarState,
        formatMessage,
        canExtendTaskAllocationBeyondProjectAllocation
      });

      onPeriodClose({
        allocation: {
          ...taskResourceUserAllocation,
          scheduleRules: taskAllocationScheduleRules
        },
        periodDetails: collapsedPeriodDetails,
        periodScheduleRules: extendedPeriodScheduleRules,
        skipCacheUpdate: true
      });
    },
    [
      resourceAllocationScheduleRules,
      userTaskAllocationsSummaryScheduleRules,
      canExtendTaskAllocationBeyondProjectAllocation,
      getResourceUserAvailabilityHoursSeriesForDateRange,
      setSnackBarState,
      formatMessage,
      onPeriodClose,
      taskResourceUserAllocation
    ]
  );

  return { onPeriodClose, onAllocationChange };
};

export default useTaskAssignmentTimelineEditorChangeHandlers;
