import useGetUsersAssignedImpactedTaskSummary from '~/modules/resourcing/common/hooks/useGetUsersAssignedImpactedTaskSummary';
import { ChangeResourceUserModificationType } from '~/types';
import { mapDateToUtcObject } from './useFormOnChangeHandlers';

const useMessageSummary = ({
  asOfDate,
  modificationType,
  existingResourceUser,
  projectId,
  releasedAllocationHours
}) => {
  const {
    isLoading: isImpactedTaskSummaryLoading,
    usersAssignedImpactedTaskSummary
  } = useGetUsersAssignedImpactedTaskSummary({
    asOfDate:
      modificationType === ChangeResourceUserModificationType.Asof
        ? mapDateToUtcObject(asOfDate)
        : null,
    projectId,
    userIds: [existingResourceUser.id]
  });

  if (isImpactedTaskSummaryLoading) {
    return { isLoading: true, messages: [], title: {} };
  }

  const taskAssignmentCount =
    usersAssignedImpactedTaskSummary?.[0]?.impactedTaskSummary.taskUris.length;

  const messages = [];

  if (releasedAllocationHours) {
    messages.push({
      messageId:
        'changeResourceDialog.impactedTaskSummary.releasedAllocationHours',
      values: { releasedAllocationHours: releasedAllocationHours.toFixed(2) }
    });
  }
  if (taskAssignmentCount) {
    messages.push({
      messageId: 'changeResourceDialog.impactedTaskSummary.taskAssignmentCount',
      values: {
        taskAssignmentCount
      }
    });
  }

  return {
    isLoading: false,
    messages,
    title: {
      messageId: 'changeResourceDialog.impactedTaskSummary.title'
    }
  };
};

export default useMessageSummary;
