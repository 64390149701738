import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useForm } from './hooks';
import MassEditProjectForm from './MassEditProjectForm';
import useCreateMassEditProjectBatch from './hooks/useCreateMassEditProjectBatch';

const confirmationDialogRole = {
  role: 'presentation'
};

export const MassEditProjectDialog = ({ open, onClose, projects }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useForm({});
  // eslint-disable-next-line no-unused-vars
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });

  const massEditProjects = useCreateMassEditProjectBatch({
    projectIds: projects,
    setBatchState,
    onClose,
    modificationInput: values
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={confirmationDialogRole}
    >
      <DialogTitle>
        <strong>
          {formatMessage({
            id: 'massEditProject.title'
          })}
        </strong>
        {formatMessage(
          {
            id: 'massEditProject.count'
          },
          { count: projects.length || 0 }
        )}
      </DialogTitle>
      <DialogContent>
        <MassEditProjectForm values={values} setFieldValue={setFieldValue} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button color="primary" type="submit" onClick={massEditProjects}>
          {formatMessage({ id: 'button.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MassEditProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired
};

export default MassEditProjectDialog;
