import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  ColumnSettingsDialog,
  useTableSettings
} from '~/modules/common/components/ListTable';
import { useDialogState } from '~/modules/common/hooks';
import SettingsButton from '~/modules/common/components/ListTableToolBar/SettingsButton';
import { useMeContext } from '~/modules/me/useMeContext';
import AvailableToBillListTable, {
  useEnabledAvailableToBillListColumnGroups
} from '../AvailableToBillListTable';
import Toolbar from './Toolbar';

const TABLE_KEY_BASE = 'available-to-bill-tab';

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paper,
    '& div div': {
      paddingRight: theme.spacing(1)
    }
  }
}));

export const AvailableToBillSubTab = ({
  searchFacets,
  permissions: { canAddBill },
  editable,
  ...rest
}) => {
  const classes = useStyles();

  const { featureFlags } = useMeContext();

  const {
    open: openSettings,
    closeDialog: closeSettingsDialog,
    openDialog: openSettingsDialog
  } = useDialogState();

  const { projectUri, programId, client } = rest;

  const tableKey = useMemo(
    () => `${TABLE_KEY_BASE}-${projectUri || programId || client?.id}`,
    [client?.id, programId, projectUri]
  );

  const {
    enabledColumnGroups,
    defaultColumns
  } = useEnabledAvailableToBillListColumnGroups(editable);

  const { data: tableSettingsData } = useTableSettings({
    tableKey,
    defaultColumns,
    skip: !featureFlags.isPsaPrpAddProjectColumnOnAvailableToBillEnabled
  });

  const columns = tableSettingsData?.columns || defaultColumns;

  return (
    <>
      {featureFlags.isPsaPrpAddProjectColumnOnAvailableToBillEnabled ? (
        <div className={classes.toolbarContainer}>
          <Toolbar searchFacets={searchFacets} />
          <SettingsButton onClick={openSettingsDialog} />
        </div>
      ) : (
        <Toolbar searchFacets={searchFacets} />
      )}
      <AvailableToBillListTable
        {...rest}
        editable={editable && canAddBill}
        visibleColumns={columns}
      />
      {openSettings && tableSettingsData && (
        <ColumnSettingsDialog
          tableKey={tableKey}
          groups={enabledColumnGroups}
          selectedColumns={columns}
          defaultColumns={defaultColumns}
          open={openSettings}
          onClose={closeSettingsDialog}
        />
      )}
    </>
  );
};

AvailableToBillSubTab.propTypes = {
  searchFacets: PropTypes.array,
  permissions: PropTypes.object.isRequired,
  editable: PropTypes.bool
};

export default AvailableToBillSubTab;
