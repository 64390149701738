import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { TextField, InputAdornment } from '@material-ui/core';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import {
  mapScaleToPeriodResolution,
  compareEditorValue,
  convertToComparableValue
} from '~/modules/resourcing/common/util';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import {
  updateResourceAllocation,
  deleteResourceAllocation
} from '~/modules/resourcing/components/ResourceAssignmentDialog/resourceRequestAllocationsReducer';
import { useAvailabilityContext } from '~/modules/resourcing/common/hooks/useAvailabilityContext';
import useQuickAllocationContext from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks/useQuickAllocationContext';
import useResourceAllocationSeriesData from '~/modules/resourcing/common/hooks/useResourceAllocationSeriesData';
import getAllocationPeriodEditorDateRange from './getAllocationPeriodEditorDateRange';
import ResourceAllocationEditorContent from './ResourceAllocationEditorContent';
import useStyles from './useStyles';

const highlightTarget = event => event.target.select();

const ResourceAllocationEditor = ({
  classes: classesOverrides,
  targetWidth,
  targetHeight,
  handlePeriodUpdate,
  handlePreviousPeriod,
  handleNextPeriod,
  periodDetails,
  isAllocationComplete = false,
  resourceAllocation,
  allocatedHours: initialAllocatedHours,
  onAllocationChange,
  scheduledHours,
  isDataLoading = false,
  isPsaRmpTaskAllocation1Enabled,
  useCreateAllocation,
  availableHours,
  getAllocationPeriodAvailabilityDateRange,
  taskAllocationHours
}) => {
  const classes = useStyles({
    targetWidth,
    targetHeight,
    classes: classesOverrides
  });

  const { displayUnit } = useDisplayUnitContext();
  const [allocatedHours, setAllocatedHours] = useState(initialAllocatedHours);

  const availabilityContext = useAvailabilityContext();
  const quickAllocationContext = useQuickAllocationContext();

  useEffect(() => setAllocatedHours(initialAllocatedHours), [
    initialAllocatedHours,
    periodDetails
  ]);

  const isHoursMode =
    displayUnit === DISPLAY_UNIT_ENUM.HOURS || scheduledHours === 0;

  const getAllocatedHours = useCallback(
    event => {
      const value =
        event.target && event.target.value ? parseFloat(event.target.value) : 0;

      return isHoursMode ? value : (value * scheduledHours) / 100;
    },
    [isHoursMode, scheduledHours]
  );

  const handleHoursChange = useCallback(
    event => {
      setAllocatedHours(getAllocatedHours(event));
    },
    [getAllocatedHours]
  );

  const { periodStartDate, periodEndDate } =
    (getAllocationPeriodAvailabilityDateRange &&
      getAllocationPeriodAvailabilityDateRange()) ||
    getAllocationPeriodEditorDateRange({
      periodDetails
    });

  const {
    resourceAllocationSeriesData,
    refetch: seriesRefetch,
    loading: seriesLoading
  } = useResourceAllocationSeriesData({
    skip: !isAllocationComplete,
    periodResolution: mapScaleToPeriodResolution('days'),
    dateRange: {
      startDate: periodStartDate,
      endDate: periodEndDate
    },
    filter: {
      userIds: [resourceAllocation.user.userUri]
    },
    includeToBeHiredAndRequested: false,
    fetchPolicy: 'cache-and-network'
  });

  const getAllocationBasedOnModificationEvent = useCallback(
    event => {
      const allocatedValue = getAllocatedHours(event);

      const isInputSame = compareEditorValue({
        isHoursMode,
        initialAllocatedHours,
        scheduledHours,
        inputValue: parseFloat(event.target.value)
      });

      if (isInputSame) {
        return { alloc: resourceAllocation, allocatedValue };
      }

      if (availabilityContext && availabilityContext.setAvailabilityLoading) {
        availabilityContext.setAvailabilityLoading();
      }

      if (
        quickAllocationContext &&
        quickAllocationContext.setAvailabilityLoading
      ) {
        quickAllocationContext.setAvailabilityLoading();
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const alloc = useCreateAllocation({
        allocation: resourceAllocation,
        newAllocatedHours: allocatedValue,
        resourceAllocationSeriesData: resourceAllocationSeriesData || []
      });

      setAllocatedHours(allocatedValue);
      onAllocationChange(
        alloc
          ? updateResourceAllocation(alloc)
          : deleteResourceAllocation(resourceAllocation),
        allocatedValue - initialAllocatedHours
      );

      return { alloc, allocatedValue };
    },
    [
      getAllocatedHours,
      isHoursMode,
      initialAllocatedHours,
      scheduledHours,
      resourceAllocation,
      availabilityContext,
      quickAllocationContext,
      useCreateAllocation,
      resourceAllocationSeriesData,
      onAllocationChange
    ]
  );
  const handleOnBlur = useCallback(
    event => {
      if (handlePeriodUpdate) {
        const { alloc } = getAllocationBasedOnModificationEvent(event);

        handlePeriodUpdate(alloc);
        event.preventDefault();
      }
    },
    [handlePeriodUpdate, getAllocationBasedOnModificationEvent]
  );

  const handleHoursKeyPress = useCallback(
    event => {
      if (handlePeriodUpdate && event.key === 'Enter') {
        handleOnBlur(event);
        event.preventDefault();
      }
    },
    [handlePeriodUpdate, handleOnBlur]
  );

  const handleHoursKeyDown = useCallback(
    event => {
      if (event.key !== 'Tab') return;
      if (event.shiftKey && handlePreviousPeriod) {
        const { alloc, allocatedValue } = getAllocationBasedOnModificationEvent(
          event
        );

        event.preventDefault();
        handlePreviousPeriod(alloc, allocatedValue);
      } else if (!event.shiftKey && handleNextPeriod) {
        const { alloc, allocatedValue } = getAllocationBasedOnModificationEvent(
          event
        );

        event.preventDefault();
        handleNextPeriod(alloc, allocatedValue);
      }
    },
    [
      handlePreviousPeriod,
      handleNextPeriod,
      getAllocationBasedOnModificationEvent
    ]
  );

  const value = convertToComparableValue({
    isHoursMode,
    hours: allocatedHours,
    scheduledHours
  });

  const inputProps = useMemo(
    () => ({
      classes: {
        input: classes.hoursFieldInput
      },
      disableUnderline: true,
      inputProps: {
        min: 0
      },
      endAdornment: (
        <InputAdornment className={classes.inputAdornment}>
          {isHoursMode ? 'h' : '%'}
        </InputAdornment>
      )
    }),
    [classes.inputAdornment, classes.hoursFieldInput, isHoursMode]
  );

  const availableValue = convertToComparableValue({
    hours: availableHours - allocatedHours,
    isHoursMode,
    scheduledHours
  });

  const taskAllocationHoursValue = convertToComparableValue({
    hours: taskAllocationHours,
    isHoursMode,
    scheduledHours
  });

  const isAvailabilityLoading =
    quickAllocationContext?.state.availability ||
    availabilityContext?.state.availability;

  return isDataLoading ? (
    <div className={classes.root}>
      <Skeleton width="80%" height={20} />
    </div>
  ) : (
    <div className={classes.root} data-qe-id="popoverInput">
      <TextField
        type="number"
        step="0.01"
        min="0"
        variant="standard"
        value={value}
        onBlur={handleOnBlur}
        onChange={handleHoursChange}
        onKeyPress={handleHoursKeyPress}
        onKeyDown={handleHoursKeyDown}
        autoFocus
        InputProps={inputProps}
        className={
          isPsaRmpTaskAllocation1Enabled
            ? classes.hoursFieldWithFF
            : classes.hoursField
        }
        onFocus={highlightTarget}
      />
      <ResourceAllocationEditorContent
        classes={classes}
        targetWidth={targetWidth}
        targetHeight={targetHeight}
        isAllocationComplete={isAllocationComplete}
        availableValue={availableValue}
        initialAllocatedHours={initialAllocatedHours}
        allocatedHours={allocatedHours}
        resourceAllocationSeriesData={resourceAllocationSeriesData}
        isAvailabilityLoading={isAvailabilityLoading}
        isHoursMode={isHoursMode}
        isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        seriesRefetch={seriesRefetch}
        seriesLoading={seriesLoading}
        taskAllocationHoursValue={taskAllocationHoursValue}
      />
    </div>
  );
};

ResourceAllocationEditor.propTypes = {
  classes: PropTypes.object,
  availableHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  scheduledHours: PropTypes.number,
  isDataLoading: PropTypes.bool,
  useCreateAllocation: PropTypes.func.isRequired,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
  handlePeriodUpdate: PropTypes.func,
  handlePreviousPeriod: PropTypes.func,
  handleNextPeriod: PropTypes.func,
  periodDetails: PropTypes.object,
  isAllocationComplete: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  resourceAllocation: PropTypes.object,
  onAllocationChange: PropTypes.func,
  getAllocationPeriodAvailabilityDateRange: PropTypes.func,
  taskAllocationHours: PropTypes.number
};

export default ResourceAllocationEditor;
