import { Grid, Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MoreMenu, MoreMenuOption } from '~/modules/common/components';
import { PROJECT_PERMISSION } from '~/modules/common/enums';
import { useDialogState } from '~/modules/common/hooks';
import { ChangeTaskResourceDialog } from '~/modules/tasks/components';
import RemoveTaskAllocationDialog from './components/RemoveTaskAllocationDialog';
import RemoveTaskEstimateConfirmationDialog from './components/RemoveTaskEstimateConfirmationDialog';
import { ResourceRoleGridItem } from './components/ResourceRoleGridItem/ResourceRoleGridItem';
import TaskAllocationCard from './components/TaskAllocationCard';
import TaskEstimateCard from './components/TaskEstimateCard';
import { useOnRemoveTaskAllocationSuccess } from './hooks/useOnChangeHandlers';
import useOnChangeTaskResourceSuccess from './hooks/useOnChangeTaskResourceSuccess';
import TaskEstimateRowContextProvider from './TaskEstimateRowContextProvider';

export const useRowStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0, 1, 0, 0),
    width: '1.5em',
    height: '100%',
    borderRadius: theme.spacing(10)
  },
  moreMenu: {
    height: 'min-content',
    alignItems: 'center',
    padding: '0 !important'
  },
  dropdownContainer: {
    width: '100%',
    margin: theme.spacing(-1, 0, 0),
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'unset'
  },
  estimateRowContainer: {
    padding: theme.spacing(1.5, 0, 2)
  }
}));

export const TaskEstimateRow = ({
  resourceEstimate,
  task,
  rowIndex,
  onRemoveResourceEstimateSuccess
}) => {
  const classes = useRowStyles();

  const canEditTasks = Boolean(
    task.project?.permittedActionUris?.includes(PROJECT_PERMISSION.EDIT_TASK)
  );

  const canViewCost = Boolean(
    task.project?.permittedActionUris?.includes(
      PROJECT_PERMISSION.VIEW_COST_DATA
    )
  );

  const {
    open: removeEstimateDialogOpen,
    openDialog: openRemoveEstimateDialog,
    closeDialog: closeRemoveEstimateDialog
  } = useDialogState(false);

  const {
    open: removeAllocationDialogOpen,
    openDialog: openRemoveAllocationDialog,
    closeDialog: closeRemoveAllocationDialog
  } = useDialogState(false);

  const {
    open: changeTaskResourceDialogOpen,
    openDialog: openChangeTaskResourceDialog,
    closeDialog: closeChangeTaskResourceDialog
  } = useDialogState(false);

  const { onRemoveTaskAllocationSuccess } = useOnRemoveTaskAllocationSuccess({
    closeRemoveAllocationDialog
  });
  const { onChangeTaskResourceSuccess } = useOnChangeTaskResourceSuccess({
    taskResourceEstimateId: resourceEstimate.id,
    canViewCost,
    taskId: task.id
  });

  return (
    <TaskEstimateRowContextProvider
      task={task}
      resourceEstimate={resourceEstimate}
      // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
      dialogProps={{ openRemoveEstimateDialog, openRemoveAllocationDialog }}
      canEditTasks={canEditTasks}
      canViewCost={canViewCost}
    >
      <Grid container className={classes.dropdownContainer}>
        <ResourceRoleGridItem
          canEditTasks={canEditTasks}
          resourceEstimate={resourceEstimate}
          task={task}
          rowIndex={rowIndex}
        />
        {canEditTasks && (
          <Grid item component={MoreMenu} className={classes.moreMenu}>
            {resourceEstimate?.resourceUser && (
              <MoreMenuOption onClick={openChangeTaskResourceDialog} dense>
                <Typography variant="body2">
                  <FormattedMessage id="taskResourceEstimates.changeResource" />
                </Typography>
              </MoreMenuOption>
            )}
            <MoreMenuOption onClick={openRemoveEstimateDialog} dense>
              <Typography variant="body2">
                <FormattedMessage id="taskResourceEstimates.remove" />
              </Typography>
            </MoreMenuOption>
          </Grid>
        )}
        {removeEstimateDialogOpen && (
          <RemoveTaskEstimateConfirmationDialog
            open={removeEstimateDialogOpen}
            onClose={closeRemoveEstimateDialog}
            task={task}
            resourceEstimate={resourceEstimate}
            onRemoveResourceEstimateSuccess={onRemoveResourceEstimateSuccess}
          />
        )}
        {removeAllocationDialogOpen && (
          <RemoveTaskAllocationDialog
            open={removeAllocationDialogOpen}
            onClose={closeRemoveAllocationDialog}
            onRemoveTaskAllocationSuccess={onRemoveTaskAllocationSuccess}
          />
        )}
        {changeTaskResourceDialogOpen && (
          <ChangeTaskResourceDialog
            onClose={closeChangeTaskResourceDialog}
            onChangeTaskResourceSuccess={onChangeTaskResourceSuccess}
            open={changeTaskResourceDialogOpen}
            resourceEstimate={resourceEstimate}
            task={task}
          />
        )}
      </Grid>

      <Grid container className={classes.estimateRowContainer} spacing={2}>
        <TaskEstimateCard
          canEditTasks={canEditTasks}
          resourceEstimate={resourceEstimate}
          rowIndex={rowIndex}
        />
        <TaskAllocationCard />
      </Grid>
    </TaskEstimateRowContextProvider>
  );
};

TaskEstimateRow.propTypes = {
  resourceEstimate: PropTypes.object,
  task: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
  onRemoveResourceEstimateSuccess: PropTypes.func
};

export default TaskEstimateRow;
