import { useState, useCallback, useMemo } from 'react';

export const getBillingLimitAlertHelper = (
  totalEstimatedAmount,
  totalEstimatedContract
) => {
  const isBelowLimit =
    totalEstimatedAmount?.amount.toFixed(2) < totalEstimatedContract?.amount;
  const isAboveLimit =
    totalEstimatedAmount?.amount.toFixed(2) > totalEstimatedContract?.amount;

  if (isBelowLimit) {
    return {
      severity: 'info',
      messageId:
        'projectFinancialsCard.limitIssuedBillsToTCV.issuedBillsLimitedToTCV'
    };
  }
  if (isAboveLimit) {
    return {
      severity: 'warning',
      messageId:
        'projectFinancialsCard.limitIssuedBillsToTCV.issuedBillsLimitedToTCV'
    };
  }

  return null;
};

const useBillingBannerHelper = ({
  editable,
  totalEstimatedAmount,
  totalEstimatedContract
}) => {
  const [showFinancialEditableCard, setShowFinancialEditableCard] = useState(
    false
  );

  const handleBannerClick = useCallback(() => {
    if (editable) setShowFinancialEditableCard(true);
  }, [editable]);

  const values = useMemo(
    () => ({
      symbol: totalEstimatedContract.currency.displayText,
      amount: totalEstimatedContract.amount.toFixed(2)
    }),
    [totalEstimatedContract.amount, totalEstimatedContract.currency.displayText]
  );

  const renderAlert = useCallback(() => {
    const alertInfo = getBillingLimitAlertHelper(
      totalEstimatedAmount,
      totalEstimatedContract
    );

    if (alertInfo) {
      return {
        severity: alertInfo.severity,
        messageId: alertInfo.messageId,
        onClick: handleBannerClick
      };
    }

    return null;
  }, [totalEstimatedAmount, totalEstimatedContract, handleBannerClick]);

  return {
    showFinancialEditableCard,
    setShowFinancialEditableCard,
    renderAlert,
    values
  };
};

export default useBillingBannerHelper;
