import React from 'react';
import { Grid, Chip, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { NoValue } from '~/modules/common/components';
import HiringRequisitionInfoTextField from './HiringRequisitionInfoTextField';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    padding: theme.spacing(1, 1, 0, 1)
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary
  }
}));

const useChipStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.dtm.text,
    backgroundColor: theme.palette.dtm.main
  }
}));

export const HiringRequisitionInfoContent = ({ requisitionInfo }) => {
  const classes = useStyles();
  const chipClasses = useChipStyles();

  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoTextField labelId="resourceRequestDrawerReadonlyDetails.requisitionId" />
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.label} noWrap>
            <FormattedMessage id="resourceRequestDrawerReadonlyDetails.status" />
          </Typography>
          {requisitionInfo?.status ? (
            <Chip
              size="medium"
              label={requisitionInfo?.status}
              classes={chipClasses}
            />
          ) : (
            <NoValue />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoTextField labelId="resourceRequestDrawerReadonlyDetails.hiringManager" />
        <HiringRequisitionInfoTextField labelId="resourceRequestDrawerReadonlyDetails.recruiter" />
      </Grid>

      <Grid container spacing={2} className={classes.container}>
        <HiringRequisitionInfoTextField labelId="resourceRequestDrawerReadonlyDetails.dateCreated" />
        <HiringRequisitionInfoTextField labelId="resourceRequestDrawerReadonlyDetails.datePosted" />
      </Grid>
    </>
  );
};

HiringRequisitionInfoContent.propTypes = {
  requisitionInfo: PropTypes.object.isRequired
};

export default HiringRequisitionInfoContent;
