import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { projectWorkFlowOptions } from '../BulkProjectStatusChangeDialog/BulkProjectStatusChangeDialogContent';

const status = <FormattedMessage id="massEditProject.status" />;

export const WorkflowDropdown = ({ value, onChange }) => {
  const intl = useIntl();
  const options = projectWorkFlowOptions({ intl });

  return (
    <SimpleDropdown
      ariaLabel={intl.formatMessage({
        id: 'massEditProject.status'
      })}
      variant="filled"
      label={status}
      noneOption={false}
      options={options}
      onChange={onChange}
      value={options.find(o => o.id === value?.id)}
    />
  );
};

WorkflowDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default WorkflowDropdown;
