import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { useMeContext } from '~/modules/me';
import { fullProjectDate2Fragment } from '../fullProject.fragment';

const customOrder = {
  PRIMARY: 1,
  BILLING: 2,
  OTHERS: 3
};

const sortContact = record => {
  return (record || []).slice().sort((a, b) => {
    return customOrder[a.contactType] - customOrder[b.contactType];
  });
};

const sortAddress = record => {
  return (record || []).slice().sort((a, b) => {
    return customOrder[a.addressType] - customOrder[b.addressType];
  });
};

export const PROJECT_DETAILS_QUERY = gql`
  query GetProjectDetails(
    $projectSlug: String!
    $isPsaEarnedRevenueEnabled: Boolean = false
    $isPsaPrpPsaPpmMergerEnabled: Boolean = false
    $isPsaPrpAddContactDetailsToTheProjectEnabled: Boolean = false
    $isPsaPrpProjectLinkEnabled: Boolean = false
    $isPsaPrp2024q4BugFixesEnabled: Boolean = false
    $isPsaPrpCappedBillingEnabled: Boolean = false
  ) {
    project(projectSlug: $projectSlug) {
      ...FullProjectDate2
      earnedRevenueScript @include(if: $isPsaEarnedRevenueEnabled) {
        displayText: name
        name
        id
      }
      projectTemplateSetting @include(if: $isPsaPrpPsaPpmMergerEnabled) {
        key
        value
      }
      addresses @include(if: $isPsaPrpAddContactDetailsToTheProjectEnabled) {
        address
        city
        country {
          id
          displayText
        }
        stateProvince
        zipPostalCode
        addressType
      }
      contacts @include(if: $isPsaPrpAddContactDetailsToTheProjectEnabled) {
        name
        email
        phoneNumber
        faxNumber
        website
        contactType
      }
      projectLinks @include(if: $isPsaPrpProjectLinkEnabled) {
        id
        linkType
        reverseLinkId
        targetProject {
          id
          name
          displayText
        }
        baseProject {
          id
          name
          displayText
        }
      }
      defaultBillSettings @include(if: $isPsaPrp2024q4BugFixesEnabled) {
        billLineItemsBy
        description
        internalNotes
        showComments
        defaultInvoicePaymentTerm
        invoiceCurrency {
          id
          symbol
        }
        taxProfile {
          id
          displayText
          description
        }
        invoiceTemplate {
          id
          displayText
        }
      }
      limitIssuedBillsToTCV @include(if: $isPsaPrpCappedBillingEnabled)
    }
  }
  ${fullProjectDate2Fragment}
`;

export const mapProjectDetails = ({
  name: projectName,
  id: projectId,
  status,
  code,
  slug,
  displayText,
  startDate,
  endDate,
  description,
  division,
  serviceCenter,
  clients,
  portfolio,
  clientRepresentative,
  program,
  projectManagerReference,
  coManagers,
  isProjectLeaderApprovalRequired,
  isTaskDateRollupEnabled,
  budgetHours,
  resourceBudgetedCost,
  expenseBudgetedCost,
  totalEstimatedContract,
  limitIssuedBillsToTCV,
  budgetedCost,
  earnedRevenueScript,
  estimatedHours,
  estimatedCost,
  billingDetails,
  billingType,
  timeAndExpenseEntryType,
  isTimeEntryAllowed,
  expenseCodes,
  defaultScheduleRule,
  projectManagementType,
  customFieldValues,
  extensionFieldValues,
  costCenter,
  location,
  slackChannel,
  department,
  employeeType,
  copyProjectJobId,
  rescheduleProjectJob,
  defaultBillingCurrency: projectCurrency,
  costType,
  permittedActionUris,
  projectTemplateSetting,
  defaultBillSettings,
  projectBillSettings,
  addresses,
  contacts,
  projectLinks
} = {}) => ({
  projectName,
  projectId,
  id: projectId,
  projectUri: projectId,
  status,
  code,
  displayText,
  startDate,
  endDate,
  description,
  division,
  slug,
  serviceCenter,
  client: clients && clients.length > 0 ? clients[0].client : null,
  clientRepresentative,
  program,
  portfolio,
  isProjectLeaderApprovalRequired,
  isTaskDateRollupEnabled,
  projectManagerReference,
  coManagers,
  budgetHours,
  totalEstimatedContract,
  limitIssuedBillsToTCV,
  budgetedCost,
  resourceBudgetedCost,
  expenseBudgetedCost,
  earnedRevenueScript,
  estimatedHours,
  estimatedCost,
  billingDetails,
  billingType,
  timeAndExpenseEntryType,
  isTimeEntryAllowed,
  expenseCodes: expenseCodes || [],
  defaultScheduleRule,
  projectManagementType,
  customFieldValues,
  extensionFieldValues,
  costCenter,
  location,
  slackChannel,
  department,
  employeeType,
  copyProjectJobId,
  rescheduleProjectJob,
  projectCurrency,
  costType,
  permittedActionUris,
  projectTemplateSetting,
  defaultBillSettings,
  projectBillSettings,
  addresses: sortAddress(addresses),
  contacts: sortContact(contacts),
  projectLinks
});

const useProjectDetails = () => {
  const {
    params: { slug }
  } = useRouteMatch();
  const {
    featureFlags: {
      isPsaEarnedRevenueEnabled,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpAddContactDetailsToTheProjectEnabled,
      isPsaPrpProjectLinkEnabled,
      isPsaPrp2024q4BugFixesEnabled,
      isPsaPrpCappedBillingEnabled
    }
  } = useMeContext();

  const { loading, data, error } = useQuery(PROJECT_DETAILS_QUERY, {
    variables: {
      projectSlug: slug,
      isPsaEarnedRevenueEnabled,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpAddContactDetailsToTheProjectEnabled,
      isPsaPrpProjectLinkEnabled,
      isPsaPrp2024q4BugFixesEnabled,
      isPsaPrpCappedBillingEnabled
    },
    skip: !slug
  });

  const { project, refetch } = data || {};

  return {
    projectDetails: {
      isProjectLoading: loading,
      projectSlug: slug,
      ...mapProjectDetails(project)
    },
    refetchProjectDetails: refetch,
    loading,
    error
  };
};

export default useProjectDetails;
