import { useCallback } from 'react';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject,
  dateToMidnightUTCObject
} from '~/modules/common/dates/convert';
import {
  getTotalHoursForDateRangeFromScheduleRules,
  roundToDecimals
} from '~/modules/resourcing/common/util';
import { ChangeResourceUserModificationType } from '~/types';

export const mapDateToUtcObject = date =>
  typeof date === 'string'
    ? dateToMidnightUTCObject(date)
    : mapRepliconDateToUtcObject(date);

export const getReleasedAllocationHours = ({
  asOfDateUtc,
  modificationType,
  resourceAllocation
}) => {
  const { scheduleRules, startDate, totalHours } = resourceAllocation;

  if (modificationType === ChangeResourceUserModificationType.Entirely) {
    return totalHours;
  }

  return (
    totalHours -
    getTotalHoursForDateRangeFromScheduleRules({
      start: mapIsoStringtoUtcObject(startDate),
      end: asOfDateUtc,
      scheduleRules
    })
  );
};

export const useReleasedAllocationSummary = ({
  asOfDate,
  modificationType,
  resourceAllocation
}) => {
  if (!resourceAllocation) {
    return {
      isLoading: true
    };
  }

  const asOfDateUtc = mapDateToUtcObject(asOfDate);

  return {
    isLoading: false,
    releasedAllocationHours: roundToDecimals(
      getReleasedAllocationHours({
        asOfDateUtc,
        modificationType,
        resourceAllocation
      })
    ),
    releasedEndDate: resourceAllocation?.endDate,
    releasedStartDate:
      modificationType === ChangeResourceUserModificationType.Entirely ||
      asOfDateUtc < mapIsoStringtoUtcObject(resourceAllocation?.startDate)
        ? resourceAllocation?.startDate
        : asOfDateUtc.plus({ day: 1 })
  };
};

const useFormOnChangeHandlers = ({ setFieldValue, setValues, values }) => {
  const onAsOfDateChange = useCallback(
    date =>
      setValues({
        ...values,
        asOfDate: date,
        newResourceUser: null
      }),
    [setValues, values]
  );

  const onChangeTypeSelect = useCallback(
    event =>
      setValues({
        ...values,
        modificationType: event.target.value,
        newResourceUser: null
      }),
    [setValues, values]
  );

  const onResourceUserChange = useCallback(
    resource => {
      setFieldValue('newResourceUser', resource);
    },
    [setFieldValue]
  );

  return {
    onAsOfDateChange,
    onChangeTypeSelect,
    onResourceUserChange
  };
};

export default useFormOnChangeHandlers;
