import { DateTime } from 'luxon';
import {
  mapRepliconDateToMidnightUTCString,
  dateToMidnightUTCObject,
  dateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { BillingFrequency } from '~/types';

export const dayOfWeekValueMap = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

const today = dateToMidnightUTCString(DateTime.utc());

export const propertiesToRelativeDateMap = {
  [BillingFrequency.Daily]: () => null,
  [BillingFrequency.Weekly]: (dayOfWeek = 'MONDAY') =>
    mapRepliconDateToMidnightUTCString({
      year: 2001,
      month: 1,
      day: dayOfWeekValueMap[dayOfWeek]
    }),
  [BillingFrequency.Biweekly]: (date = today) => date,
  [BillingFrequency.Semimonthly]: (dayOfMonth = 1) =>
    mapRepliconDateToMidnightUTCString({
      year: 2001,
      month: 1,
      day: parseInt(dayOfMonth, 10)
    }),
  [BillingFrequency.Monthly]: (dayOfMonth = 1) =>
    mapRepliconDateToMidnightUTCString({
      year: 2001,
      month: 1,
      day: parseInt(dayOfMonth, 10)
    }),
  [BillingFrequency.Quarterly]: (date = today) => date,
  [BillingFrequency.Yearly]: (date = today) => date
};

export const relativeDateToPropertyMap = {
  [BillingFrequency.Weekly]: relativeDate =>
    Object.keys(dayOfWeekValueMap).find(
      k =>
        dayOfWeekValueMap[k] === dateToMidnightUTCObject(relativeDate).weekday
    ),
  [BillingFrequency.Semimonthly]: relativeDate =>
    dateToMidnightUTCObject(relativeDate).day,
  [BillingFrequency.Monthly]: relativeDate =>
    dateToMidnightUTCObject(relativeDate).day
};

export const getDayOfMonth = relativeDate =>
  dateToMidnightUTCObject(relativeDate).day;

export const getDayOfWeek = relativeDate =>
  Object.keys(dayOfWeekValueMap).find(
    k => dayOfWeekValueMap[k] === dateToMidnightUTCObject(relativeDate).weekday
  );
