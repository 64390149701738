import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { isoToReplicon } from '~/util';
import { mapToServiceFilter } from '../utils';

export const availableToBillRowsQuery = gql`
  query getPageOfAvailableToBill(
    $page: Int!
    $pageSize: Int!
    $filter: AvailableToBillFilter2
    $includeClient: Boolean!
    $includeProject: Boolean!
    $includeProjectLeader: Boolean!
    $includeBillingFrequencyPeriod: Boolean!
    $includeAmount: Boolean!
  ) {
    getPageOfAvailableToBill: getPageOfAvailableToBill2(
      page: $page
      pageSize: $pageSize
      filter: $filter
    ) {
      id
      client @include(if: $includeClient) {
        id
        displayText
      }
      project @include(if: $includeProject) {
        id
        displayText
      }
      projectLeader @include(if: $includeProjectLeader) {
        id
        displayText
      }
      billingFrequencyPeriod @include(if: $includeBillingFrequencyPeriod) {
        startDate: startDate2
        endDate: endDate2
      }
      startDate: startDate2 @skip(if: $includeBillingFrequencyPeriod)
      endDate: endDate2 @skip(if: $includeBillingFrequencyPeriod)
      amount @include(if: $includeAmount) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const useAvailableToBillRows = ({
  page = 1,
  pageSize = 100,
  projectUri,
  client,
  programId,
  availableToBillFilter,
  visibleColumns,
  isPsaPrpIncludeNonZeroAmountOnlyInAvailableToBillEnabled,
  isPsaPrpAddProjectColumnOnAvailableToBillEnabled
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const {
    data,
    fetchMore,
    loading,
    refetch: refetchAvailableToBillRows,
    variables
  } = useQuery(availableToBillRowsQuery, {
    variables: {
      page,
      pageSize,
      filter: mapToServiceFilter({
        projectUri,
        client,
        programUri: programId,
        availableToBillFilter,
        isPsaPrpIncludeNonZeroAmountOnlyInAvailableToBillEnabled,
        isPsaPrpAddProjectColumnOnAvailableToBillEnabled
      }),
      includeClient: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
        ? visibleColumns.includes('client')
        : true,
      includeAmount: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
        ? visibleColumns.includes('amount')
        : true,
      includeProject: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
        ? visibleColumns.includes('project')
        : false,
      includeProjectLeader: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
        ? visibleColumns.includes('projectLeader')
        : false,
      includeBillingFrequencyPeriod: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
        ? visibleColumns.includes('date')
        : false
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const availableToBillRows = get(data, 'getPageOfAvailableToBill', []).map(
    availableBill => {
      const startDate = get(
        availableBill,
        isPsaPrpAddProjectColumnOnAvailableToBillEnabled
          ? 'billingFrequencyPeriod.startDate'
          : 'startDate'
      );
      const endDate = get(
        availableBill,
        isPsaPrpAddProjectColumnOnAvailableToBillEnabled
          ? 'billingFrequencyPeriod.endDate'
          : 'endDate'
      );

      return {
        ...availableBill,
        startDate: startDate ? isoToReplicon(startDate) : startDate,
        endDate: endDate ? isoToReplicon(endDate) : endDate
      };
    }
  );

  const hasMoreRows =
    availableToBillRows &&
    availableToBillRows.length !== 0 &&
    availableToBillRows.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(async () => {
    if (!hasMoreRows || isLoadingMore) {
      return;
    }

    setIsLoadingMore(true);

    try {
      await fetchMore({
        query: availableToBillRowsQuery,
        variables: {
          page: availableToBillRows.length / variables.pageSize + 1,
          filter: variables.filter,
          pageSize: variables.pageSize
        },
        updateQuery: (
          previousResult,
          { fetchMoreResult: { getPageOfAvailableToBill: nextResult } }
        ) => ({
          getPageOfAvailableToBill: [
            ...previousResult.getPageOfAvailableToBill,
            ...nextResult
          ]
        })
      });
    } finally {
      setIsLoadingMore(false);
    }
  }, [
    availableToBillRows.length,
    fetchMore,
    setIsLoadingMore,
    isLoadingMore,
    hasMoreRows,
    variables.filter,
    variables.pageSize
  ]);

  return {
    availableToBillRows,
    hasMoreRows,
    isLoadingMore,
    refetchAvailableToBillRows,
    loadMoreRows,
    isRowsLoading: loading
  };
};
