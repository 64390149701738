import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, makeStyles, Typography, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary
  },
  value: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary
  },
  email: {
    ...theme.typography.body2,
    color: theme.palette.dtm.main
  },
  item: {
    padding: theme.spacing(0, 1)
  }
}));

export const HiringRequisitionInfoTextField = ({ labelId, value, email }) => {
  const classes = useStyles();

  return (
    <Grid item xs={6} className={classes.item}>
      <Typography variant="body2" className={classes.label} noWrap>
        <FormattedMessage id={labelId} />
      </Typography>
      {value ? (
        <Typography variant="subtitle2" className={classes.value} noWrap>
          {value}
        </Typography>
      ) : (
        <NoValue />
      )}
      {email && (
        <Typography variant="body2" className={classes.email} noWrap>
          <Link href={`mailto:${email}`} target="_blank">
            {email}
          </Link>
        </Typography>
      )}
    </Grid>
  );
};

HiringRequisitionInfoTextField.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  email: PropTypes.string
};

export default HiringRequisitionInfoTextField;
