import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import TimeAndExpenseEntryTypeDropdown2 from '~/modules/tasks/components/TimeAndExpenseEntryTypeDropdown/TimeAndExpenseEntryTypeDropdown2';
import {
  ClientDropdownForProject,
  ProgramDropdownForProject
} from '~/modules/projects/components/Common';
import {
  CostTypeDropdown,
  SearchableProjectManagerDropdown,
  SearchableClientRepDropdown
} from '~/modules/projects/project/common/components';
import { CurrencyDropdown } from '~/modules/common/components';
import { WorkflowDropdown } from './WorkflowDropdown';
import { TimeSheetApprovalDropdown } from './TimeSheetApprovalDropdown';
import { TimeEntryDropdown } from './TimeEntryDropdown';
import { useChangeHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  item: {
    paddingRight: `30px !important`
  }
}));

const projectCurrencyLabel = (
  <FormattedMessage id="massEditProject.projectCurrency" />
);

const projectTimeAndExpenseEntryLabel = (
  <FormattedMessage id="massEditProject.projectTimeAndExpenseEntry" />
);

const MassEditProjectForm = ({ values, setFieldValue }) => {
  const classes = useStyles();
  const {
    onWorkflowStageChange,
    onCurrencyChange,
    onClientChange,
    onClientRepresentativeChange,
    onProgramChange,
    onCostTypeChange,
    onProjectManagerChange,
    onTimesheetApprovalChange,
    onTimeAndExpenseEntryChange,
    onTimeEntryChange
  } = useChangeHandlers({
    setFieldValue
  });

  return (
    <Grid container>
      <Grid item xs={6} className={classes.item}>
        <WorkflowDropdown
          value={values.workflowStage}
          onChange={onWorkflowStageChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <CurrencyDropdown
          variant="filled"
          label={projectCurrencyLabel}
          editable
          noneOption={false}
          value={values.currency}
          onChange={onCurrencyChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <ClientDropdownForProject
          variant="filled"
          value={values.client}
          onChange={onClientChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <SearchableClientRepDropdown
          clientUri={values.client?.id}
          value={values.clientRepresentative}
          onChange={onClientRepresentativeChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <ProgramDropdownForProject
          variant="filled"
          value={values.program}
          onChange={onProgramChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <CostTypeDropdown value={values.costType} onChange={onCostTypeChange} />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <SearchableProjectManagerDropdown
          value={values.projectManager}
          onChange={onProjectManagerChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <TimeSheetApprovalDropdown
          value={values.projectManagerTimesheetApproval}
          onChange={onTimesheetApprovalChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <TimeAndExpenseEntryTypeDropdown2
          fullWidth
          variant="filled"
          label={projectTimeAndExpenseEntryLabel}
          value={values.timeAndExpenseEntryType?.id}
          onChange={onTimeAndExpenseEntryChange}
        />
      </Grid>
      <Grid item xs={6} className={classes.item}>
        <TimeEntryDropdown
          value={values.timeEntry}
          onChange={onTimeEntryChange}
        />
      </Grid>
    </Grid>
  );
};

MassEditProjectForm.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default MassEditProjectForm;
