import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { extractFirstValidationError } from '~/modules/common/graphql/errors';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';

export const UPDATE_CLIENT_BILLING_INFO = gql`
  mutation UpdateClientBilling($input: UpdateClientBillingInfoInput!) {
    updateClientBillingInfo2(input: $input) {
      client {
        id
        defaultInvoicePaymentTerm
        invoiceCurrency {
          id
          symbol
        }
        taxProfile {
          id
          displayText
        }
        invoiceTemplate {
          id
          displayText
        }
        billingSettings {
          billLineItemsBy
          description
          internalNotes
          showComments
          billFrequency
          billFrequencyRelativeDate
          consolidateBillsBy
        }
      }
    }
  }
`;

export const useUpdateClientBillingInfo = () => {
  const [updateClientBillingInfo] = useMutation(UPDATE_CLIENT_BILLING_INFO);

  const onSubmit = useCallback(
    async (values, { resetForm, setSubmitting, setFieldError }) => {
      const {
        id,
        taxProfile,
        invoiceCurrency,
        defaultInvoicePaymentTerm,
        invoiceTemplate,
        billLineItemsBy,
        description,
        internalNotes,
        showComments,
        billFrequency,
        billFrequencyRelativeDate,
        consolidateBillsBy
      } = values;

      try {
        await updateClientBillingInfo({
          variables: {
            input: {
              id,
              taxProfileUri: taxProfile ? taxProfile.id : null,
              invoiceCurrencyUri: invoiceCurrency.id,
              defaultInvoicePaymentTerm: defaultInvoicePaymentTerm || 0,
              invoiceTemplateUri: invoiceTemplate ? invoiceTemplate.id : null,
              billLineItemsBy: billLineItemsBy || [BILLING_COLUMN_TYPE.PROJECT],
              description: description || null,
              internalNotes: internalNotes || null,
              showComments: showComments || false,
              billFrequency,
              billFrequencyRelativeDate,
              consolidateBillsBy
            }
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateClientBillingInfo2: {
              __typename: 'UpdateClientBillingInfoResponse',
              client: {
                __typename: 'Client',
                id,
                taxProfile,
                invoiceCurrency,
                defaultInvoicePaymentTerm,
                invoiceTemplate,
                billingSettings: {
                  __typename: 'BillingSettings',
                  billLineItemsBy,
                  description,
                  internalNotes,
                  showComments,
                  billFrequency,
                  billFrequencyRelativeDate,
                  consolidateBillsBy
                }
              }
            }
          }
        });
        resetForm();
      } catch (error) {
        const validationErrors = extractFirstValidationError(error);

        setFieldError('billingInfo', validationErrors);
      } finally {
        setSubmitting(false);
      }
    },
    [updateClientBillingInfo]
  );

  return { onSubmit };
};

export default useUpdateClientBillingInfo;
