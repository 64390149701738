import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CREATE_MASS_EDIT_PROJECT_BATCH_MUTATION = gql`
  mutation CreateMassProjectInfoModificationBatch(
    $projectIds: [String!]!
    $modificationInput: ProjectInfoModificationInput!
  ) {
    createMassProjectInfoModificationBatch(
      projectIds: $projectIds
      modificationInput: $modificationInput
    )
  }
`;

export const useCreateMassEditProjectBatch = ({
  projectIds,
  setBatchState,
  onClose,
  modificationInput
}) => {
  const [massEditProjectBatch] = useMutation(
    CREATE_MASS_EDIT_PROJECT_BATCH_MUTATION
  );

  const {
    workflowStage,
    currency,
    client,
    clientRepresentative,
    program,
    costType,
    projectManager,
    projectManagerTimesheetApproval,
    timeAndExpenseEntryType,
    timeEntry
  } = modificationInput;

  return useCallback(async () => {
    setBatchState({
      batchId: null,
      batchInProgress: true
    });
    const { data, error } = await massEditProjectBatch({
      variables: {
        projectIds: projectIds.map(p => p.id),
        modificationInput: {
          clientUri: client?.id,
          clientRepresentativeUri: clientRepresentative?.id,
          programUri: program?.id,
          projectManagerUri: projectManager?.id,
          projectWorkflowState: workflowStage?.id,
          isProjectLeaderApprovalRequired: !projectManagerTimesheetApproval?.id
            ? projectManagerTimesheetApproval
            : projectManagerTimesheetApproval.id === 'required'
            ? true
            : projectManagerTimesheetApproval.id === 'noApproval'
            ? false
            : null,
          costTypeUri: costType?.id,
          timeAndExpenseEntryTypeUri: timeAndExpenseEntryType?.id,
          isTimeEntryAllowed: !timeEntry?.id
            ? timeEntry
            : timeEntry.id === 'taskSelectionOptional'
            ? true
            : timeEntry.id === 'taskSelectionRequired'
            ? false
            : null,
          defaultBillingCurrencyUri: currency?.id
        }
      }
    });

    const batchId = data?.createMassProjectInfoModificationBatch;

    // Temporary until results dialog is merged
    onClose();

    setBatchState({
      batchId,
      batchInProgress: !error
    });
  }, [
    setBatchState,
    massEditProjectBatch,
    projectIds,
    client?.id,
    clientRepresentative?.id,
    program?.id,
    projectManager?.id,
    workflowStage?.id,
    projectManagerTimesheetApproval,
    costType?.id,
    timeAndExpenseEntryType?.id,
    timeEntry,
    currency?.id,
    onClose
  ]);
};

export default useCreateMassEditProjectBatch;
