import { useApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { formattedBillingAddress } from '~/modules/billing-invoicing/bill/formattedBillingAddress';
import { useMeContext } from '~/modules/me';
import {
  toRepliconDate,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { useBulkCreateBillFromBillItemsBatch } from './useBulkCreateBillFromBillItemsBatch';
import { useFetchClientsDefaultData } from './useFetchClientsDefaultData';
import { useFetchInvoiceDefaultTemplate } from './useFetchInvoiceDefaultTemplate';
import { useBulkGetProjectOrClientBillingDefaultsForAvailableToBill } from './useBulkProjectOrClientBillingDefaultsForAvailableToBill';

export const getUniqueClientIds = selectedAvailableToBillItems => [
  ...new Set(selectedAvailableToBillItems.map(({ client }) => client.id))
];

const mapToBulkCreateBillInput = ({
  billingItem: {
    startDate,
    endDate,
    client: { id: clientId }
  },
  defaultInvoiceTemplate,
  projectOrClientBillingSetting,
  projectUri,
  me: {
    baseCurrency,
    featureFlags: { isPsaPrpCommentsOnInvoiceEnabled }
  }
}) => {
  const {
    poNumber,
    defaultInvoicePaymentTerm = 30,
    invoiceCurrency,
    taxProfile,
    invoiceTemplate,
    billLineItemsBy,
    description,
    internalNotes,
    showComments,
    billingContactInfo
  } = projectOrClientBillingSetting || {};

  const issueDate = DateTime.local();
  const columns = billLineItemsBy || ['PROJECT'];

  return {
    billInput: {
      billingAddress:
        typeof billingContactInfo === 'object'
          ? formattedBillingAddress(billingContactInfo)
          : '',
      description,
      clientUri: clientId,
      currencyUri: invoiceCurrency?.id || baseCurrency.id,
      poNumber,
      issueDate: toRepliconDate(issueDate),
      paymentTerms: defaultInvoicePaymentTerm,
      dueDate: toRepliconDate(
        issueDate.plus({ days: defaultInvoicePaymentTerm })
      ),
      invoiceTemplateUri: invoiceTemplate?.id || defaultInvoiceTemplate?.id,
      taxProfileUri: taxProfile?.id,
      period: {
        startDate,
        endDate
      },
      summarizeColumn: columns,
      internalNotes,
      showComments: isPsaPrpCommentsOnInvoiceEnabled ? showComments : null
    },
    billFromBillingItemsBatchParameter: {
      billingItemColumnOption: columns,
      billingItemSearch: {
        projectUris: projectUri ? [projectUri] : null,
        dateRange: {
          startDate: mapRepliconDateToMidnightUTCString(startDate),
          endDate: mapRepliconDateToMidnightUTCString(endDate)
        }
      }
    },
    allowEmptyInvoice: false
  };
};

const mapToBulkCreateBillInputLegacy = ({
  billingItem,
  defaultInvoiceTemplate,
  clientDetails,
  baseCurrency,
  projectUri,
  clientId,
  isPsaPrpCommentsOnInvoiceEnabled,
  isPsaPrpBillingDefaultForProjectEnabled
}) => {
  const {
    id = clientId,
    invoiceCurrency,
    defaultInvoicePaymentTerm = 30,
    invoiceTemplate,
    taxProfile,
    billingContactInfo,
    billingSettings
  } = clientDetails || {};

  const issueDate = DateTime.local();
  const columns = billingSettings?.billLineItemsBy || ['PROJECT'];

  return {
    billInput: {
      billingAddress:
        typeof billingContactInfo === 'object'
          ? formattedBillingAddress(billingContactInfo)
          : '',
      description: billingSettings?.description,
      clientUri: id,
      currencyUri: invoiceCurrency?.id || baseCurrency.id,
      issueDate: toRepliconDate(issueDate),
      paymentTerms: defaultInvoicePaymentTerm,
      dueDate: toRepliconDate(
        issueDate.plus({ days: defaultInvoicePaymentTerm })
      ),
      invoiceTemplateUri: invoiceTemplate?.id || defaultInvoiceTemplate?.id,
      taxProfileUri: isPsaPrpBillingDefaultForProjectEnabled
        ? taxProfile?.id
        : null,
      period: {
        startDate: billingItem.startDate,
        endDate: billingItem.endDate
      },
      summarizeColumn: columns,
      internalNotes: billingSettings?.internalNotes,
      showComments: isPsaPrpCommentsOnInvoiceEnabled
        ? billingSettings?.showComments
        : null
    },
    billFromBillingItemsBatchParameter: {
      billingItemColumnOption: columns,
      billingItemSearch: {
        projectUris: projectUri ? [projectUri] : null,
        dateRange: {
          startDate: mapRepliconDateToMidnightUTCString(billingItem.startDate),
          endDate: mapRepliconDateToMidnightUTCString(billingItem.endDate)
        }
      }
    },
    allowEmptyInvoice: false
  };
};

export const useBulkCreateBill = ({
  selectedAvailableToBillItems,
  setBillingBatchState,
  projectUri
}) => {
  const apolloClient = useApolloClient();
  const me = useMeContext();
  const {
    isPsaPrpBillingDefaultForProjectEnabled,
    isPsaPrpAddProjectColumnOnAvailableToBillEnabled
  } = me.featureFlags;

  const [bulkBillCreationInitiated, setBulkBillCreationInitiated] = useState(
    false
  );
  const {
    bulkCreateBillFromBillingItemsBatch,
    loading
  } = useBulkCreateBillFromBillItemsBatch({
    setBillingBatchState
  });

  const { fetchClientsDefaultData } = useFetchClientsDefaultData({
    apolloClient
  });

  const {
    fetchBulkGetProjectBillingDefaultsForAvailableToBill
  } = useBulkGetProjectOrClientBillingDefaultsForAvailableToBill(apolloClient);

  const { fetchInvoiceDefaultTemplate } = useFetchInvoiceDefaultTemplate({
    apolloClient,
    isPsaPrpBillingDefaultForProjectEnabled
  });

  return {
    bulkCreateBill: async () => {
      const clientIds = getUniqueClientIds(selectedAvailableToBillItems);

      if (!clientIds?.length) return;

      if (isPsaPrpBillingDefaultForProjectEnabled) {
        setBulkBillCreationInitiated(true);
        const [
          projectOrClientBillingSettings,
          defaultInvoiceTemplate
        ] = await Promise.all([
          fetchBulkGetProjectBillingDefaultsForAvailableToBill(
            selectedAvailableToBillItems.map(item => ({
              clientUris: [item.client.id],
              projectUris: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
                ? projectUri
                  ? [projectUri]
                  : item.project?.id
                  ? [item.project?.id]
                  : undefined
                : projectUri
                ? [projectUri]
                : undefined,
              billingPeriod: {
                startDate: mapRepliconDateToMidnightUTCString(item.startDate),
                endDate: mapRepliconDateToMidnightUTCString(item.endDate)
              }
            }))
          ),
          fetchInvoiceDefaultTemplate()
        ]);

        bulkCreateBillFromBillingItemsBatch(
          selectedAvailableToBillItems
            .filter(item => item.startDate && item.endDate)
            .map((item, index) =>
              mapToBulkCreateBillInput({
                billingItem: item,
                defaultInvoiceTemplate,
                projectOrClientBillingSetting:
                  projectOrClientBillingSettings[index],
                projectUri: isPsaPrpAddProjectColumnOnAvailableToBillEnabled
                  ? projectUri || item.project?.id
                  : projectUri,
                me
              })
            )
        );
        setBulkBillCreationInitiated(false);
      } else {
        const [clientDetailsById, defaultInvoiceTemplate] = await Promise.all([
          fetchClientsDefaultData(clientIds),
          fetchInvoiceDefaultTemplate()
        ]);

        bulkCreateBillFromBillingItemsBatch(
          selectedAvailableToBillItems
            .filter(item => item.startDate && item.endDate)
            .map(item =>
              mapToBulkCreateBillInputLegacy({
                billingItem: item,
                defaultInvoiceTemplate: isPsaPrpBillingDefaultForProjectEnabled
                  ? defaultInvoiceTemplate
                  : defaultInvoiceTemplate?.data?.defaultInvoiceTemplate,
                clientDetails: clientDetailsById[item.client.id],
                clientId: item.client.id,
                baseCurrency: me.baseCurrency,
                projectUri,
                isPsaPrpCommentsOnInvoiceEnabled:
                  me?.featureFlags?.isPsaPrpCommentsOnInvoiceEnabled,
                isPsaPrpBillingDefaultForProjectEnabled:
                  me?.featureFlags?.isPsaPrpBillingDefaultForProjectEnabled
              })
            )
        );
      }
    },
    loading: isPsaPrpBillingDefaultForProjectEnabled
      ? bulkBillCreationInitiated || loading
      : loading
  };
};
