import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption
  },
  hoursField: ({ targetWidth }) => ({
    width: Math.max(targetWidth || 0, theme.spacing(4.5))
  }),
  hoursFieldWithFF: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0, 0, 0.5)
  },
  hoursFieldInput: ({ targetHeight }) => ({
    textAlign: 'right',
    padding: theme.spacing(0.5, 0),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    height: Math.max(targetHeight || 0, theme.spacing(2))
  }),
  inputAdornment: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0.5)
  },
  detailsText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  details: {
    display: 'flex',
    padding: theme.spacing(0.25, 0.25),
    textAlign: 'right',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary
  },
  overAllocated: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  },
  value: {
    marginLeft: theme.spacing(1)
  },
  editorDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.25, 0.25),
    textAlign: 'right',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary
  },
  availability: {
    display: 'flex',
    alignItems: 'center'
  },
  tasks: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltipHeader: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightBold
  },
  tooltipDescription: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightRegular
  },
  icon: {
    color: theme.palette.text.secondary
  },
  taskIcon: {
    color: theme.palette.text.secondary,
    marginRight: 'auto'
  },
  taskTooltip: {
    padding: theme.spacing(1, 0, 0)
  }
}));

export const useAvailabilityPeriodStyles = makeStyles(theme => ({
  overAllocated: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  },
  value: {
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
