import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  useDialogState,
  useProjectsStatusCountSummaryQuery
} from '~/modules/common/hooks';
import {
  SelectionBar,
  BulkDeleteConfirmationDialog,
  BatchStatus
} from '~/modules/common/components';
import { ListTablePropTypes } from '~/modules/common/components/ListTable';
import { useProjectFilterWithExcludingArchiveProjects } from '~/modules/common/components/ProjectsQuickFilterSummary/useProjectFilterWithExcludingArchiveProjects';
import { ProjectManagementType } from '~/types';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import BulkProjectStatusChangeDialog from './BulkProjectStatusChangeDialog';
import { useProjectFilterMemo } from './useProjectFilterMemo';
import { MassEditProjectDialog } from './MassEditProjectDialog';

export const BulkActionsForProjects = ({
  projects,
  selected,
  onSelectionChange,
  refetchProjects,
  refetchTotals
}) => {
  const projectIds = projects.map(p => p.id);
  const [batchState, setBatchState] = useState({
    batchId: null,
    batchInProgress: false
  });
  const [bulkUpdateProjectState, setBulkUpdateProjectState] = useState({
    inProgress: false,
    complete: false
  });
  const { open, closeDialog, openDialog } = useDialogState();

  const {
    open: statusDialogOpen,
    closeDialog: closeStatusDialog,
    openDialog: openStatusDialog
  } = useDialogState();

  const {
    open: massEditDialogOpen,
    closeDialog: closeMassEditDialog,
    openDialog: openMassEditDialog
  } = useDialogState();

  const { projectType, searchCriteria } = useProjectsContext();

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria
  });

  const { refetch: managedCountRefech } = useProjectsStatusCountSummaryQuery({
    isManaged: true,
    projectFilter,
    skip: true
  });

  const { refetch: unManagedCountRefech } = useProjectsStatusCountSummaryQuery({
    isManaged: false,
    projectFilter,
    skip: true
  });

  const filterWithExcludingArchiveProjects = useProjectFilterWithExcludingArchiveProjects(
    {
      projectFilter
    }
  );

  const {
    refetch: allProjectsStatusCountRefetch
  } = useProjectsStatusCountSummaryQuery({
    isManaged: projectType === ProjectManagementType.Managed,
    projectFilter: filterWithExcludingArchiveProjects,
    skip: true
  });

  const {
    refetch: myProjectsStatusCountRefetch
  } = useProjectsStatusCountSummaryQuery({
    isManaged: projectType === ProjectManagementType.Managed,
    projectFilter: {
      ...filterWithExcludingArchiveProjects,
      includeMyProjectsOnly: true
    },
    skip: true
  });

  const {
    refetch: archivedProjectsCountRefetch
  } = useProjectsStatusCountSummaryQuery({
    isManaged: projectType === ProjectManagementType.Managed,
    projectFilter,
    skip: true
  });

  const onClear = useCallback(() => {
    const newSelected = {
      ...selected,
      records: selected.records.filter(id => !projectIds.includes(id))
    };

    onSelectionChange(newSelected);
  }, [projectIds, selected, onSelectionChange]);

  const onBatchComplete = useCallback(
    async state => {
      onClear();
      managedCountRefech();
      unManagedCountRefech();
      allProjectsStatusCountRefetch();
      myProjectsStatusCountRefetch();
      archivedProjectsCountRefetch();
      refetchProjects();
      refetchTotals && refetchTotals();
    },
    [
      onClear,
      allProjectsStatusCountRefetch,
      archivedProjectsCountRefetch,
      managedCountRefech,
      myProjectsStatusCountRefetch,
      refetchProjects,
      refetchTotals,
      unManagedCountRefech
    ]
  );

  const handleClose = useCallback(
    bulkDeletaBatchState => {
      if (
        bulkDeletaBatchState.batchId &&
        !bulkDeletaBatchState.batchInProgress
      ) {
        onBatchComplete(bulkDeletaBatchState);
      } else if (bulkDeletaBatchState.batchId) {
        setBatchState({
          batchId: bulkDeletaBatchState.batchId,
          batchInProgress: bulkDeletaBatchState.batchInProgress
        });
      }
      closeDialog();
    },
    [closeDialog, setBatchState, onBatchComplete]
  );

  const handleBatchComplete = useCallback(() => {
    onBatchComplete(batchState);
  }, [batchState, onBatchComplete]);

  if (projectIds.length <= 0) return null;

  return (
    <>
      <SelectionBar
        count={projectIds.length}
        onClear={onClear}
        onRemove={openDialog}
        onStatusChange={openStatusDialog}
        onClickMassEdit={openMassEditDialog}
      />
      {open && (
        <BulkDeleteConfirmationDialog
          open={open}
          objectType="PROJECT"
          onClose={handleClose}
          objectIds={projectIds}
        />
      )}
      {statusDialogOpen && (
        <BulkProjectStatusChangeDialog
          open={statusDialogOpen}
          onClose={closeStatusDialog}
          projects={projects}
          onBatchComplete={onBatchComplete}
          bulkUpdateProjectState={bulkUpdateProjectState}
          setBulkUpdateProjectState={setBulkUpdateProjectState}
        />
      )}
      {massEditDialogOpen && (
        <MassEditProjectDialog
          open={massEditDialogOpen}
          onClose={closeMassEditDialog}
          projects={projects}
        />
      )}
      {batchState.batchInProgress && (
        <BatchStatus
          batchState={batchState}
          setBatchState={setBatchState}
          onBatchComplete={handleBatchComplete}
        >
          <></>
        </BatchStatus>
      )}
    </>
  );
};

BulkActionsForProjects.propTypes = {
  projects: PropTypes.array.isRequired,
  selected: ListTablePropTypes.selected,
  onSelectionChange: PropTypes.func.isRequired,
  refetchProjects: PropTypes.func.isRequired,
  refetchTotals: PropTypes.func
};

export default BulkActionsForProjects;
