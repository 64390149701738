import { Grid, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import {
  HoursIcon,
  KPIIcon,
  CostIcon,
  ProgressIcon,
  ProfitIcon
} from '~/modules/common/components/Icons';
import { FileAttachment } from '~/modules/attachments/AttachmentsBlock';
import useProjectProgressCardSettingsStore from '~/modules/projects/project/charts/hooks/useProjectProgressCardSettingsStore';
import {
  ChartCard,
  ProgressSummaryChartHelp
} from '~/modules/common/charts/dashboard';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import { usePageTitle } from '~/modules/common/title';
import { useScrollToTop } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me/useMeContext';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import ResourceHoursHelp from '~/modules/common/CardHelp/ResourceHoursHelp';
import ResourceCostHelp from '~/modules/common/CardHelp/ResourceCostHelp';
import { AdvancedRateCard } from '~/modules/rateCard/advancedRateCard';
import { CUSTOM_METADATA_BUILDER } from '~/modules/attachments/uploader/attachmentUtil';
import ProjectRiskSummary from '~/modules/project-risk-summary';
import Billing from '../Billing';
import BillPlan from '../BillPlanV2';
import {
  CostsChart,
  HoursChart,
  ProjectOverviewChart,
  ProjectProgressChart
} from '../charts';
import { BudgetSummaryChart } from '../charts/BudgetChart';
import Financials from '../Financials';
import ProjectInfoDetails from '../ProjectInfoDetails';
import RateCard from '../RateCard';
import BillingRateCard from '../BillingRateCard';
import RevenueRecognition from '../RevenueRecognition';
import TimeAndExpense from '../TimeAndExpense';
import EarnedRevenue from '../EarnedRevenue';
import ProjectBillingCard from '../ProjectBillingCard';
import ProjectInfoLGUpViewView from './ProjectInfoLGUpViewView';
import ProjectInfoMDDownView from './ProjectInfoMDDownView';
import ProjectTagsCard from './ProjectTagsCard';
import useProjectInfoPermissions from './useProjectInfoPermissions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    minHeight: '-webkit-max-content'
  },
  gridContainer: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2)
    }
  },
  projectOverview: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2)
    }
  },
  chartCardContent: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}));

const useChartCardStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const resourceKeys = {
  noRates: 'rateCard.noRates.project'
};

const progressChartAction = projectSummaryChartProps => (
  <ProgressSummaryChartHelp
    projectSummaryChartProps={projectSummaryChartProps}
  />
);

const icons = {
  hours: <HoursIcon />,
  cost: <CostIcon />,
  progress: <ProgressIcon />,
  overview: <KPIIcon />,
  profit: <ProfitIcon />
};

const resourceHoursHelpAction = <ResourceHoursHelp type="project" />;
const resourceCostHelpAction = <ResourceCostHelp type="project" />;

const dateRange = {
  startDate: DateTime.local().startOf('year'),
  endDate: DateTime.local().endOf('year')
};

const getVisibleCards = ({
  me,
  labels,
  editable,
  projectDetails,
  chartClasses,
  costChartDataProps,
  onSetCurrencyChanged,
  isProjectCurrencyChanged,
  onSetBillPlanChanged,
  isPsaFpAdvancedRateCardEnabled,
  isPsaPrpBillingDefaultForProjectEnabled,
  isPsaPrpCappedBillingEnabled
}) => {
  const {
    billingDetails,
    billingType,
    budgetedCost,
    resourceBudgetedCost,
    expenseBudgetedCost,
    budgetHours,
    client,
    costType,
    estimatedCost,
    estimatedHours,
    expenseCodes,
    isProjectLoading,
    permittedActionUris,
    projectCurrency,
    projectId,
    slug,
    totalEstimatedContract,
    limitIssuedBillsToTCV,
    projectTemplateSetting
  } = projectDetails;

  const {
    isExpenseProductEnabled,
    featureFlags: {
      isPsaEarnedRevenueEnabled,
      isPsaPraaProjectDocumentObjectTaggingEnabled
    }
  } = me;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const projectPermissions = useProjectInfoPermissions({
    permittedActionUris,
    billingType,
    projectTemplateSetting
  });
  const {
    canEditProject,
    canEditBillingContracts,
    canEditBillingRates,
    canEditProjectEstimates,
    canViewBilling,
    canEditBilling,
    canViewBillingContracts,
    canViewRevenueContracts,
    canViewBillingRates,
    canViewProjectCostData,
    canViewProjectEstimates,
    canViewDocuments,
    canEditDocuments,
    canRecalculateBillingData,
    canViewTeam,
    canEditRevenueContracts,
    canEditExpenseCodes
  } = projectPermissions;

  const isBillingContractType =
    billingDetails &&
    billingDetails.billingTypeUri ===
      'urn:replicon:billing-type:billing-contract';

  const isTimeAndMaterialType =
    billingDetails &&
    billingDetails.billingTypeUri ===
      'urn:replicon:billing-type:time-and-material';

  const hasBillingContractAssignment = Boolean(
    billingDetails &&
      billingDetails.details &&
      billingDetails.details.billingContract
  );

  const editExpenseCodes = Boolean(
    editable && (canEditProject || canEditExpenseCodes)
  );

  const hoursChartDataProps = { projectId };

  // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
  const financialProps = {
    editable: editable && canEditProjectEstimates,
    onSetCurrencyChanged,
    projectPermissions,
    projectUri: projectId,
    costType,
    budgetHours,
    totalEstimatedContract,
    limitIssuedBillsToTCV,
    budgetedCost,
    resourceBudgetedCost,
    expenseBudgetedCost,
    estimatedHours,
    estimatedCost,
    projectCurrency,
    isProjectLoading
  };

  return [
    canViewProjectEstimates && (
      <ChartCard
        key="ProjectHoursChart"
        chartTitle={labels.hours}
        titleId="projectHours"
        chartAvatar={icons.hours}
        chartDataProps={hoursChartDataProps}
        ChartComponent={HoursChart}
        dateRange={dateRange}
        scale={PERIOD_SCALE_ENUM.YEARS}
        showPeriodFilter
        classes={chartClasses}
        chartAction={resourceHoursHelpAction}
      />
    ),
    canViewProjectCostData && canViewProjectEstimates && (
      <ChartCard
        key="ProjectCostsChart"
        chartTitle={labels.cost}
        titleId="projectCost"
        chartAvatar={icons.cost}
        ChartComponent={CostsChart}
        dateRange={dateRange}
        scale={PERIOD_SCALE_ENUM.YEARS}
        showPeriodFilter
        classes={chartClasses}
        chartDataProps={costChartDataProps}
        chartAction={resourceCostHelpAction}
      />
    ),
    ...(canViewBillingRates && isBillingContractType
      ? [
          isPsaFpAdvancedRateCardEnabled ? (
            <AdvancedRateCard
              key="advancedRateCard"
              editable={editable && canEditBillingRates}
              projectId={projectId}
              projectCurrency={projectCurrency}
              projectSlug={slug}
              projectPermissions={projectPermissions}
            />
          ) : (
            <RateCard
              key="rateCard"
              context="project"
              editable={editable && canEditBillingRates}
              clientUri={client ? client.id : null}
              projectUri={projectId}
              projectSlug={slug}
              scale={PERIOD_SCALE_ENUM.YEARS}
              resourceKeys={resourceKeys}
              me={me}
            />
          )
        ]
      : []),
    ...(isBillingContractType && canViewBillingContracts
      ? [
          <BillPlan
            key="billPlan"
            editable={editable && canEditBillingContracts}
            projectUri={projectId}
            hasBillingContractAssignment={hasBillingContractAssignment}
            canRecalculateBillingData={canRecalculateBillingData}
            isProjectCurrencyChanged={isProjectCurrencyChanged}
            onSetBillPlanChanged={onSetBillPlanChanged}
            clientUri={client ? client.id : null}
            projectSlug={slug}
            projectPermissions={projectPermissions}
            isBillingContractType={isBillingContractType}
            financialProps={isPsaPrpCappedBillingEnabled ? financialProps : {}}
          />
        ]
      : []),
    ...(canViewBillingRates && isTimeAndMaterialType
      ? [
          <BillingRateCard
            key="billingRateCard"
            context="project"
            editable={editable && canEditBillingRates}
            projectUri={projectId}
            projectSlug={slug}
            canViewTeam={canViewTeam}
          />
        ]
      : []),
    ...(canViewProjectEstimates
      ? [<Financials key="financial" {...financialProps} />]
      : []),
    ...(canViewBilling && !isBillingContractType && !isTimeAndMaterialType
      ? [
          <Billing
            key="billing"
            editable={editable}
            billingDetails={billingDetails}
            isProjectLoading={isProjectLoading}
          />
        ]
      : []),
    ...(isExpenseProductEnabled
      ? [
          <TimeAndExpense
            key="timeAndExpense"
            editable={editExpenseCodes}
            projectUri={projectId}
            projectCurrency={projectCurrency}
            expenseCodes={expenseCodes}
            projectPermissions={projectPermissions}
            isProjectLoading={isProjectLoading}
            projectDetails={projectDetails}
            hasBillingContractAssignment={hasBillingContractAssignment}
            canRecalculateBillingData={canRecalculateBillingData}
            isProjectCurrencyChanged={isProjectCurrencyChanged}
            onSetBillPlanChanged={onSetBillPlanChanged}
            isBillingContractType={isBillingContractType}
          />
        ]
      : []),
    ...(canViewRevenueContracts
      ? [
          <RevenueRecognition
            key="revenue"
            editable={editable && canEditRevenueContracts}
            projectUri={projectId}
          />
        ]
      : []),

    ...(canViewDocuments
      ? [
          <FileAttachment
            key="attachments"
            attachToUri={projectId}
            editable={editable && canEditDocuments}
            isLoading={isProjectLoading}
            customMetadataFn={
              isPsaPraaProjectDocumentObjectTaggingEnabled
                ? CUSTOM_METADATA_BUILDER.PROJECT
                : undefined
            }
          />
        ]
      : []),
    <ProjectTagsCard
      projectUri={projectId}
      editable={editable && canEditProject}
      key="tags"
    />,
    ...(isPsaEarnedRevenueEnabled
      ? [<EarnedRevenue key="earnedRevenue" projectUri={projectId} />]
      : []),
    ...(isPsaPrpBillingDefaultForProjectEnabled && canViewBilling
      ? [
          <ProjectBillingCard
            key="projectBillingCard"
            editable={editable && canEditBilling}
            isLoading={isProjectLoading}
            projectDetails={projectDetails}
          />
        ]
      : [])
  ];
};

export const ProjectInfo = ({
  classes: classesOverride,
  editable,
  ...projectDetails
}) => {
  const [isProjectCurrencyChanged, setIsProjectCurrencyChanged] = useState(
    false
  );
  const [isBillPlanChanged, setIsBillPlanChanged] = useState(false);
  const [
    isProjectCurrencyChangedKey,
    setIsProjectCurrencyChangedKey
  ] = useState(uuid());
  const [isBillPlanChangedKey, setIsBillPlanChangedKey] = useState(uuid());

  const onSetCurrencyChanged = useCallback(() => {
    setIsProjectCurrencyChanged(true);
    setTimeout(() => {
      setIsProjectCurrencyChanged(false);
      setIsProjectCurrencyChangedKey(uuid());
    }, 30000);
  }, []);

  const onSetBillPlanChanged = useCallback(() => {
    setIsBillPlanChanged(true);
    setTimeout(() => {
      setIsBillPlanChanged(false);
      setIsBillPlanChangedKey(uuid());
    }, 30000);
  }, []);

  const me = useMeContext();
  const classes = useStyles({ classes: classesOverride });
  const { formatMessage } = useIntl();
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: {
      isPsaFpAdvancedRateCardEnabled,
      isPsaPrpBillingDefaultForProjectEnabled,
      isPsaPrpCappedBillingEnabled
    }
  } = me;

  const labels = {
    hours: formatMessage({ id: 'projectPage.resourceHours' }),
    cost: formatMessage({ id: 'projectPage.resourceCost' }),
    overview: formatMessage({ id: 'projectPage.overview' }),
    profitability: formatMessage({ id: 'projectPage.profitability' }),
    progress: formatMessage({ id: 'projectPage.progress' }),
    projectCost: formatMessage({ id: 'projectPage.projectCost' })
  };

  const projectPermissions = useProjectInfoPermissions({ ...projectDetails });

  const {
    projectCurrency,
    projectId,
    projectName,
    isProjectLoading
  } = projectDetails;

  usePageTitle(
    isProjectLoading
      ? []
      : [
          { messageId: 'routes.projects' },
          { title: projectName },
          { messageId: 'projectPageTabs.summary' }
        ]
  );

  const chartClasses = useChartCardStyles();

  const yAxisLabelValue = useMemo(
    () =>
      projectCurrency?.displayText
        ? { value: projectCurrency.displayText }
        : null,
    [projectCurrency]
  );

  const yAxisLabel = useMemo(
    () =>
      yAxisLabelValue
        ? formatMessage({ id: 'chart.cost' }, yAxisLabelValue)
        : '',
    [formatMessage, yAxisLabelValue]
  );

  const costChartDataProps = useMemo(
    () =>
      projectCurrency
        ? { currencyId: projectCurrency.id, yAxisLabel, projectId }
        : {},
    [projectCurrency, projectId, yAxisLabel]
  );

  const visibleCards = getVisibleCards({
    labels,
    me,
    editable,
    projectDetails,
    chartClasses,
    costChartDataProps,
    onSetCurrencyChanged,
    isProjectCurrencyChanged,
    onSetBillPlanChanged,
    isBillPlanChanged,
    formatMessage,
    isPsaFpAdvancedRateCardEnabled,
    isPsaPrpBillingDefaultForProjectEnabled,
    isPsaPrpCappedBillingEnabled
  });
  const overViewChartDataProps = useMemo(
    () => ({
      projectPermissions,
      projectId,
      isProjectCurrencyChanged,
      key: isProjectCurrencyChanged
        ? isProjectCurrencyChangedKey
        : isBillPlanChangedKey,
      isBillPlanChanged
    }),
    [
      projectPermissions,
      projectId,
      isProjectCurrencyChanged,
      isProjectCurrencyChangedKey,
      isBillPlanChanged,
      isBillPlanChangedKey
    ]
  );

  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = me;

  const showCostContent =
    projectPermissions.canViewProjectCostData &&
    isPsaPpmEstimatedCostAtCompletionEnabled;

  const {
    loading: projectProgressCardSettingsLoading,
    projectProgressCardSetting,
    saveProjectProgressCardSetting
  } = useProjectProgressCardSettingsStore({ showCostContent });

  const progressChartDataProps = useMemo(
    () => ({
      projectId,
      startDate: projectDetails.startDate,
      endDate: projectDetails.endDate,
      showCostContent,
      projectCurrency,
      projectProgressCardSettingsLoading,
      projectProgressView: projectProgressCardSetting?.selectedView,
      saveProjectProgressCardSetting
    }),
    [
      projectId,
      projectDetails.startDate,
      projectDetails.endDate,
      showCostContent,
      projectCurrency,
      projectProgressCardSettingsLoading,
      projectProgressCardSetting,
      saveProjectProgressCardSetting
    ]
  );

  const projectSummaryChartProps = useMemo(
    () => ({
      showCostContent,
      projectProgressCardSettingsLoading,
      projectProgressView: projectProgressCardSetting?.selectedView,
      saveProjectProgressCardSetting
    }),
    [
      showCostContent,
      projectProgressCardSettingsLoading,
      projectProgressCardSetting,
      saveProjectProgressCardSetting
    ]
  );

  useScrollToTop();

  return (
    <div className={classes.container}>
      <div>
        <ProjectInfoDetails showEdit={editable} {...projectDetails} />
      </div>
      <Hidden smUp>
        {isRolledUpTaskEstimateCalculationMethodEnabled && (
          <Grid item xs={12} className={classes.projectOverview}>
            <ErrorBoundary key="ProjectsProgressChart">
              <ChartCard
                classes={chartClasses}
                chartTitle={labels.progress}
                chartAvatar={icons.progress}
                chartDataProps={progressChartDataProps}
                ChartComponent={ProjectProgressChart}
                showEntireProjectScaleOption
                chartAction={progressChartAction(projectSummaryChartProps)}
              />
            </ErrorBoundary>
          </Grid>
        )}
        <ProjectRiskSummary
          projectUri={projectId}
          projectEndDate={projectDetails.endDate}
          classes={chartClasses}
        />
        <Grid item xs={12} className={classes.projectOverview}>
          <ErrorBoundary key="ProjectsOverviewChart">
            <ChartCard
              classes={chartClasses}
              chartTitle={
                !projectPermissions.canViewProjectBillingData
                  ? labels.projectCost
                  : isRolledUpTaskEstimateCalculationMethodEnabled
                  ? labels.profitability
                  : labels.overview
              }
              chartAvatar={
                projectPermissions.canViewProjectBillingData &&
                isRolledUpTaskEstimateCalculationMethodEnabled
                  ? icons.profit
                  : icons.overview
              }
              chartDataProps={overViewChartDataProps}
              ChartComponent={ProjectOverviewChart}
              showPeriodFilter
              showScaleSelector
              showEntireProjectScaleOption
            />
          </ErrorBoundary>
          {projectPermissions.canViewProjectEstimates && (
            <BudgetSummaryChart projectDetails={projectDetails} />
          )}
          {visibleCards}
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid spacing={0} container className={classes.gridContainer}>
          <ProjectInfoMDDownView
            visibleCards={visibleCards}
            projectDetails={projectDetails}
            canViewBudgetChart={projectPermissions.canViewProjectEstimates}
            canViewBillingData={projectPermissions.canViewProjectBillingData}
            overViewChartDataProps={overViewChartDataProps}
            progressChartDataProps={progressChartDataProps}
            isProjectCurrencyChangedKey={isProjectCurrencyChangedKey}
          />
          <ProjectInfoLGUpViewView
            visibleCards={visibleCards}
            projectDetails={projectDetails}
            canViewBudgetChart={projectPermissions.canViewProjectEstimates}
            canViewBillingData={projectPermissions.canViewProjectBillingData}
            overViewChartDataProps={overViewChartDataProps}
            progressChartDataProps={progressChartDataProps}
            isProjectCurrencyChangedKey={isProjectCurrencyChangedKey}
          />
        </Grid>
      </Hidden>
    </div>
  );
};

ProjectInfo.propTypes = {
  classes: PropTypes.object,
  editable: PropTypes.bool
};

export default ProjectInfo;
