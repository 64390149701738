import React from 'react';
import { DialogContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ResourceUserSelectionRow } from '~/modules/tasks/components/ChangeTaskResourceDialog/components';
import { BatchInProgressIndicator } from '~/modules/common/components';
import {
  ChangeResourceAllocationRangeOptions,
  ImpactedTaskSummaryAlert,
  MergeErrorAlert
} from './components';
import { useFormOnChangeHandlers, useMessageSummary } from './hooks';
import { useReleasedAllocationSummary } from './hooks/useFormOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export const ChangeResourceDialogContent = ({
  existingResourceUser,
  hasTaskAssignmentConflict,
  isTaskAssignmentConflictLoading,
  projectId,
  resourceAllocation
}) => {
  const classes = useStyles();

  const { isSubmitting, setFieldValue, setValues, values } = useFormikContext();

  const { asOfDate, modificationType, newResourceUser } = values;

  const {
    isLoading: isReleasedAllocationSummaryLoading,
    releasedAllocationHours,
    releasedEndDate,
    releasedStartDate
  } = useReleasedAllocationSummary({
    asOfDate,
    modificationType,
    resourceAllocation
  });

  const {
    isLoading: isMessagesSummaryLoading,
    messages,
    title
  } = useMessageSummary({
    asOfDate,
    modificationType,
    existingResourceUser,
    projectId,
    releasedAllocationHours
  });

  const {
    onAsOfDateChange,
    onChangeTypeSelect,
    onResourceUserChange
  } = useFormOnChangeHandlers({ setFieldValue, setValues, values });

  return (
    <DialogContent classes={classes}>
      {isSubmitting ? (
        <BatchInProgressIndicator messageId="changeResourceDialog.changingResource" />
      ) : (
        <>
          <ChangeResourceAllocationRangeOptions
            asOfDate={asOfDate}
            modificationType={modificationType}
            onAsOfDateChange={onAsOfDateChange}
            onChangeTypeSelect={onChangeTypeSelect}
          />
          <ResourceUserSelectionRow
            endDate={releasedEndDate}
            existingResourceUser={existingResourceUser}
            initialEstimatedHours={releasedAllocationHours}
            hideAvailability={
              isReleasedAllocationSummaryLoading || !releasedAllocationHours
            }
            newResourceUser={newResourceUser}
            onResourceUserChange={onResourceUserChange}
            projectId={projectId}
            startDate={releasedStartDate}
          />
          <ImpactedTaskSummaryAlert
            isLoading={
              isReleasedAllocationSummaryLoading || isMessagesSummaryLoading
            }
            messages={messages}
            severity="info"
            title={title}
          />
          {newResourceUser && (
            <MergeErrorAlert
              existingResourceUser={existingResourceUser}
              hasError={hasTaskAssignmentConflict}
              isLoading={isTaskAssignmentConflictLoading}
              newResourceUser={newResourceUser}
            />
          )}
        </>
      )}
    </DialogContent>
  );
};

ChangeResourceDialogContent.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  hasTaskAssignmentConflict: PropTypes.bool,
  isTaskAssignmentConflictLoading: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  resourceAllocation: PropTypes.object
};

export default ChangeResourceDialogContent;
